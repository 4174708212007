import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { hideModal, selectUser, setIdentityProperties } from "@adm-media/adam-store-plugin";
import { CHANGE_PASSWORD_MODAL } from "../../../modals";
import { useAppDispatch } from "../../../../store";
import { ChangePassword } from "../../../functional/ChangePassword/ChangePassword";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DucatiConfirmRegistration } from "../DucatiConfirmRegistration/DucatiConfirmRegistration";
export var DucatiChangePasswordModal = function () {
    var change_password_required = useSelector(selectUser).identity.change_password_required;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var onSuccessCallback = function () {
        navigate("/");
        dispatch(hideModal({ name: CHANGE_PASSWORD_MODAL }));
        // dispatch(displayModal({ name: LOGIN_MODAL }));
    };
    var onSuccessCallbackConfirmRegistration = function () {
        dispatch(setIdentityProperties({ change_password_required: 0 }));
        dispatch(hideModal({ name: CHANGE_PASSWORD_MODAL }));
    };
    return (_jsxs(_Fragment, { children: [(change_password_required === 1 || change_password_required === 2) && _jsx(ChangePassword, { onSuccessCallback: onSuccessCallback }), change_password_required === 3 && _jsx(DucatiConfirmRegistration, { onSuccessCallback: onSuccessCallbackConfirmRegistration })] }));
};
export var getDucatiChangePasswordModalProps = function () {
    return {
        modalClassName: "change-password-modal",
        maskClosable: false,
        closable: false,
        footer: null
    };
};
