import { jsx as _jsx } from "react/jsx-runtime";
export var DucatiDevtoolsWarningModal = function () {
    return _jsx("div", { children: "Alert! console open" });
};
export var getDucatiDevtoolsWarningModalProps = function () {
    return {
        modalClassName: "devtools-warning-modal",
        maskClosable: false,
        footer: null
    };
};
