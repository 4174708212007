var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { Spin } from "../Spin/Spin";
import "./Player.scss";
import { useSelector } from "react-redux";
import { selectDocumentVisibilityStatus, selectEventId } from "@adm-media/adam-store-plugin";
import { exitFullScreenMode } from "../../../utils/player";
import { useMutationObservable } from "../../../hooks/useMutationObserver";
var Player = function (_a) {
    var currentTime = _a.currentTime, source = _a.source, onPlay = _a.onPlay, onPause = _a.onPause, onReadyStateChange = _a.onReadyStateChange, onPlayerReady = _a.onPlayerReady, onTimeUpdate = _a.onTimeUpdate, onAudioTrackChange = _a.onAudioTrackChange, onTextTrackChange = _a.onTextTrackChange, onVolumeChange = _a.onVolumeChange, isFakeLive = _a.isFakeLive, _b = _a.loop, loop = _b === void 0 ? false : _b, _c = _a.widescreen, widescreen = _c === void 0 ? false : _c, _d = _a.hideControls, hideControls = _d === void 0 ? false : _d, _e = _a.autoplay, autoplay = _e === void 0 ? false : _e, _f = _a.muted, muted = _f === void 0 ? true : _f, _g = _a.forceLiveUi, forceLiveUi = _g === void 0 ? false : _g, _h = _a.forcedLayout, forcedLayout = _h === void 0 ? false : _h, additionalUi = _a.additionalUi, hideSpeed = _a.hideSpeed, disableFullScreen = _a.disableFullScreen;
    var playerRef = useRef();
    var wrapperRef = useRef();
    var documentVisibilityStatus = useSelector(selectDocumentVisibilityStatus);
    var eventId = useSelector(selectEventId);
    var _j = useState(undefined), playerReference = _j[0], setPlayerReference = _j[1];
    useEffect(function () {
        var player = new window.THEOplayer.Player(playerRef.current, {
            libraryLocation: "https://cdn.myth.theoplayer.com/".concat(process.env.THEO_API_KEY),
            license: process.env.THEO_API_LICENSE,
            fluid: true
            // ui: {
            //     language: "es",
            //     languages: {
            //         es: {
            //             Subtitles: "Subtítulos"
            //         }
            //     }
            // }
        });
        // player.source = source;
        player.preload = "auto";
        player.muted = muted;
        player.autoplay = autoplay;
        player.loop = loop;
        // const interceptor = (request: InterceptableRequest) => {
        //     request.redirect({
        //         ...request,
        //         headers: {
        //             ...request.headers,
        //             "X-ADAM": eventId || ""
        //         }
        //     });
        // };
        // player.network.addRequestInterceptor(interceptor);
        // https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement/disablePictureInPicture
        try {
            var videoElement = player.element.querySelector("video");
            if (videoElement)
                videoElement.setAttribute("disablePictureInPicture", "true");
            if (videoElement)
                videoElement.setAttribute("disableRemotePlayback", "true");
        }
        catch (_a) { }
        if (currentTime !== undefined) {
            player.currentTime = currentTime;
        }
        if (onPlayerReady) {
            onPlayerReady(player);
        }
        player.addEventListener("play", function () { return onPlay && onPlay(player); });
        /**https://docs.theoplayer.com/api-reference/web/theoplayer.readystatechangeevent.md */
        player.addEventListener("readystatechange", function () { return onReadyStateChange && onReadyStateChange(player, player === null || player === void 0 ? void 0 : player.readyState); });
        player.addEventListener("volumechange", function () { return onVolumeChange && onVolumeChange(player); });
        player.addEventListener("timeupdate", function () { return onTimeUpdate && onTimeUpdate(player); });
        setPlayerReference(player);
    }, []);
    var onFullScreenCallback = useCallback(function () {
        try {
            if (disableFullScreen)
                exitFullScreenMode();
        }
        catch (_a) {
            console.warn("Error while exiting fullscreen");
        }
    }, [disableFullScreen]);
    useEffect(function () {
        document.addEventListener("fullscreenchange", onFullScreenCallback);
        return function () { return document.removeEventListener("fullscreenchange", onFullScreenCallback); };
    }, [disableFullScreen]);
    var onPauseCallback = useCallback(function () {
        if (isFakeLive && documentVisibilityStatus === "visible" && playerReference) {
            if (typeof (playerReference === null || playerReference === void 0 ? void 0 : playerReference.duration) === "number" &&
                typeof (playerReference === null || playerReference === void 0 ? void 0 : playerReference.currentTime) === "number" &&
                !Number.isNaN(playerReference === null || playerReference === void 0 ? void 0 : playerReference.duration) &&
                !Number.isNaN(playerReference === null || playerReference === void 0 ? void 0 : playerReference.currentTime) &&
                (playerReference === null || playerReference === void 0 ? void 0 : playerReference.duration) > (playerReference === null || playerReference === void 0 ? void 0 : playerReference.currentTime)) {
                playerReference === null || playerReference === void 0 ? void 0 : playerReference.play();
            }
        }
        else
            onPause && onPause(playerReference);
    }, [isFakeLive, playerReference, documentVisibilityStatus]);
    useEffect(function () {
        playerReference === null || playerReference === void 0 ? void 0 : playerReference.addEventListener("pause", onPauseCallback);
        return function () { return playerReference === null || playerReference === void 0 ? void 0 : playerReference.removeEventListener("pause", onPauseCallback); };
    }, [playerReference, onPauseCallback]);
    var audioTrackChangeCallback = useCallback(function (track) {
        onAudioTrackChange && onAudioTrackChange(playerReference, track);
    }, [onAudioTrackChange, playerReference]);
    var ccTrackChangeCallback = useCallback(function () {
        onTextTrackChange && onTextTrackChange(playerReference);
    }, [onTextTrackChange, playerReference]);
    useEffect(function () {
        if (playerReference)
            playerReference === null || playerReference === void 0 ? void 0 : playerReference.textTracks.addEventListener("change", ccTrackChangeCallback);
        return function () { return playerReference === null || playerReference === void 0 ? void 0 : playerReference.textTracks.removeEventListener("change", ccTrackChangeCallback); };
    }, [playerReference, ccTrackChangeCallback]);
    useEffect(function () {
        if (playerReference)
            playerReference === null || playerReference === void 0 ? void 0 : playerReference.audioTracks.addEventListener("change", audioTrackChangeCallback);
        return function () { return playerReference === null || playerReference === void 0 ? void 0 : playerReference.audioTracks.removeEventListener("change", audioTrackChangeCallback); };
    }, [playerReference, audioTrackChangeCallback]);
    useEffect(function () {
        // Transition between videos. If source of player change we need to update only src
        // https://docs.theoplayer.com/getting-started/01-sdks/01-web/04-how-to-implement-seamless-transition.md
        // if (source && playerReference) {
        //     playerReference.src = source.sources[0]?.src;
        // }
        if (source && playerReference)
            playerReference.source = source;
    }, [source, playerReference]);
    var updateTextTracksPositionCallback = useCallback(function () {
        var _a, _b, _c;
        var htmlCollection = document.getElementsByClassName("theoplayer-texttracks");
        var areControlsVisible = (((_a = playerRef === null || playerRef === void 0 ? void 0 : playerRef.current) === null || _a === void 0 ? void 0 : _a.classList.contains("vjs-user-active")) || ((_b = playerRef === null || playerRef === void 0 ? void 0 : playerRef.current) === null || _b === void 0 ? void 0 : _b.classList.contains("vjs-paused"))) &&
            ((_c = playerRef === null || playerRef === void 0 ? void 0 : playerRef.current) === null || _c === void 0 ? void 0 : _c.classList.contains("vjs-has-started"));
        if (areControlsVisible) {
            for (var _i = 0, htmlCollection_1 = htmlCollection; _i < htmlCollection_1.length; _i++) {
                var element = htmlCollection_1[_i];
                element === null || element === void 0 ? void 0 : element.classList.remove("bottom-0");
            }
        }
        else {
            for (var _d = 0, htmlCollection_2 = htmlCollection; _d < htmlCollection_2.length; _d++) {
                var element = htmlCollection_2[_d];
                element === null || element === void 0 ? void 0 : element.classList.add("bottom-0");
            }
        }
    }, []);
    useMutationObservable(playerRef.current, updateTextTracksPositionCallback);
    // Handle loop
    useEffect(function () {
        if (playerReference) {
            playerReference.loop = loop;
        }
    }, [loop, playerReference]);
    // Handle currentTime
    useEffect(function () {
        if (playerReference && currentTime !== undefined) {
            playerReference.currentTime = currentTime;
        }
    }, [currentTime, playerReference]);
    useEffect(function () {
        // Disable context menu in player
        wrapperRef.current.addEventListener("contextmenu", function (e) {
            e.preventDefault();
            e.stopPropagation();
        }, true);
    }, [wrapperRef]);
    // Listen for programmatically unmute with button (within Wrapper)
    useEffect(function () {
        if (playerReference) {
            playerReference.muted = muted;
        }
    }, [muted, playerReference]);
    // Destroy
    useEffect(function () {
        return function () {
            if (playerReference) {
                playerReference.destroy();
            }
        };
    }, [playerReference]);
    return (_jsxs("div", __assign({ ref: wrapperRef, className: classNames("player-wrapper", {
            "force-live": forceLiveUi,
            "player-wrapper--disable-fullscreen": disableFullScreen
        }) }, { children: [forcedLayout && (_jsx("div", __assign({ className: "player-cover-layout" }, { children: _jsx(Spin, __assign({ className: "player-spinner", size: "large", spinning: true }, { children: _jsx("div", {}) })) }))), _jsx("div", { 
                // vjs-16-9 THEOplayer are not necessary, but just added for demo purposes
                className: "theoplayer-container video-js theoplayer-skin hide-casts ".concat(widescreen && "vjs-16-9", " ").concat(hideControls && "hide-controls", " ").concat(hideSpeed && "hide-speed", " "), 
                // The ref prop here is key it returns the actual dom element and not the virtual react dom elements
                // Which is need by the player
                ref: playerRef }), additionalUi] })));
};
export default Player;
