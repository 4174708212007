var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import "./DucatiProfileFlyout.scss";
import { Tooltip } from "antd";
import classNames from "classnames";
export var DucatiProfileFlyout = function (_a) {
    var _b;
    var children = _a.children, flyoutContent = _a.flyoutContent;
    var _c = useState(false), isTooltipOpen = _c[0], setIsTooltipOpen = _c[1];
    var baseClass = "ducati-profile-flyout";
    var elementBaseClasses = classNames(baseClass, (_b = {},
        _b["".concat(baseClass, "--active")] = isTooltipOpen,
        _b));
    return (_jsx("div", __assign({ className: elementBaseClasses, "data-testid": "ducati-profile-flyout" }, { children: _jsx(Tooltip, __assign({ className: "".concat(baseClass, "__tooltip"), overlayClassName: "".concat(baseClass, "__tooltip-card"), trigger: "click", destroyTooltipOnHide: true, onOpenChange: function (isOpen) { return setIsTooltipOpen(isOpen); }, title: _jsx("div", __assign({ className: "".concat(baseClass, "__flyout-content") }, { children: flyoutContent })) }, { children: _jsx("div", __assign({ className: "".concat(baseClass, "__target"), tabIndex: 0, "data-testid": "ducati-profile-flyout-target" }, { children: children })) })) })));
};
