var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SanitizeHtml } from "../components/ui/SanitizeHtml/SanitizeHtml";
import { useSelector } from "react-redux";
import { selectAppContent, selectMedia } from "@adm-media/adam-store-plugin";
import DucatiFooter from "../components/project-specific/ducati/DucatiFooter/DucatiFooter";
export var DucatiPlaceholderPage = function () {
    var _a, _b, _c;
    var contents = useSelector(selectAppContent);
    var media = useSelector(selectMedia);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "d-hero-banner" }, { children: [_jsxs("picture", __assign({ className: "picture" }, { children: [_jsx("source", { srcSet: (_a = media.body_background_image_desktop) === null || _a === void 0 ? void 0 : _a.url, media: "(min-width: 739px)" }), _jsx("source", { srcSet: (_b = media.body_background_image_mobile) === null || _b === void 0 ? void 0 : _b.url, media: "(min-width: 0px)" }), _jsx("img", { srcSet: (_c = media.body_background_image_mobile) === null || _c === void 0 ? void 0 : _c.url, alt: "", "data-object-fit": "cover" })] })), _jsxs("section", __assign({ className: "body" }, { children: [_jsx("div", __assign({ className: "subtitle" }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.sub_sub_title, tag: "div" }) })), _jsx("h1", __assign({ className: "title" }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents === null || contents === void 0 ? void 0 : contents.event_title, tag: "div" }) })), _jsx("div", __assign({ className: "txt" }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.sub_title, tag: "div" }) })), _jsx("div", { className: "wrapshare" }), _jsx("div", { className: "wrapcta" })] }))] })), _jsx(DucatiFooter, {})] }));
};
