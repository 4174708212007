import { setSelectedEpisodeId, selectLocalStorageEpisodeKey, selectEventId, selectSelectedEpisodeId, clearSource, clearActiveMultipleSource, resetSubscribeChannelPresenceThunk, setSelectedAudioLanguage, setSelectedCCLanguage, resetLastPlayerLoggedId } from "@adm-media/adam-store-plugin";
import { batch, useDispatch, useSelector } from "react-redux";
/**
 * Hook to manage dis
 * @returns
 */
export var useSetCurrentEpisode = function () {
    var dispatch = useDispatch();
    var localStorageId = useSelector(selectLocalStorageEpisodeKey);
    var eventId = useSelector(selectEventId);
    var selectedEpisodeId = useSelector(selectSelectedEpisodeId);
    var setCurrentEpisode = function (_a) {
        var episodeId = _a.episodeId, doReset = _a.doReset;
        if (episodeId !== null) {
            localStorage.setItem(localStorageId, String(episodeId));
        }
        else
            localStorage.removeItem(localStorageId);
        batch(function () {
            if (episodeId !== null) {
                /* DUCATI ONLY: do not do the /select-episode call as it is not needed */
                // dispatch(registerSelectedEpisodeThunk({ eventId: eventId!, episodeId }));
            }
            else if (episodeId === null) {
                dispatch(clearSource());
                dispatch(resetLastPlayerLoggedId());
                dispatch(clearActiveMultipleSource());
                dispatch(setSelectedAudioLanguage(null));
                dispatch(setSelectedCCLanguage(null));
            }
            dispatch(setSelectedEpisodeId(episodeId));
        });
        if (episodeId === null && selectedEpisodeId !== null && doReset) {
            dispatch(clearSource());
            dispatch(resetLastPlayerLoggedId());
            dispatch(clearActiveMultipleSource());
            dispatch(setSelectedAudioLanguage(null));
            dispatch(setSelectedCCLanguage(null));
            dispatch(resetSubscribeChannelPresenceThunk({}));
        }
    };
    return { setCurrentEpisode: setCurrentEpisode };
};
