import { PusherMock } from "pusher-js-mock";
export var NEW_NOTIFICATION_PUSHER_EVENT = "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated";
export var subscribeToPusherNotificationChannel = function (_a) {
    var pusherInstance = _a.pusherInstance, subscriptionCode = _a.subscriptionCode, callback = _a.callback;
    if (window === null || window === void 0 ? void 0 : window.Cypress) {
        var pusherInstance_1 = new PusherMock();
        fetch(process.env.PUSHER_BASE_URL + "broadcasting/auth/notification-channel", { method: "POST" });
        var channel = pusherInstance_1.subscribe("private-subscribers.".concat(subscriptionCode));
        channel.bind(NEW_NOTIFICATION_PUSHER_EVENT, function (notification) {
            callback(notification);
        });
        channel.unsubscribe = function () { };
        return channel;
    }
    else {
        try {
            if (pusherInstance) {
                var channel = pusherInstance.subscribe("private-subscribers.".concat(subscriptionCode));
                channel.bind(NEW_NOTIFICATION_PUSHER_EVENT, function (notification) {
                    callback(notification);
                });
                return channel;
            }
            else
                return null;
        }
        catch (_b) {
            console.warn("Error in pusher connection / notifications");
            return null;
        }
    }
};
