var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { selectAppLanguages, selectLocaleLanguage } from "@adm-media/adam-store-plugin";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useChangeAppLocale } from "../../../../hooks/useChangeAppLocale";
import { DownOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { Modal } from "antd";
import "./DucatiFixedLanguageSelector.scss";
import { useGetDucatiContents } from "../contents/useGetDucatiContents";
import { ducatiLanguages } from "../contents/ducatiContents";
export var DucatiFixedLanguageSelector = function (_a) {
    var setFooterEventListener = _a.setFooterEventListener, afterLanguageChangeCallback = _a.afterLanguageChangeCallback;
    var baseClassModal = "ducati-fixed-language-selector-modal";
    var currentLocale = useSelector(selectLocaleLanguage);
    var languages = useSelector(selectAppLanguages);
    var changeAppLocale = useChangeAppLocale().changeAppLocale;
    var _b = useState(false), isLanguageChangeModalOpen = _b[0], setIsLanguageChangeModalOpen = _b[1];
    var ducatiContents = useGetDucatiContents();
    var handleLangChange = function (e, language) {
        e.preventDefault();
        changeAppLocale({
            newLocale: language
        });
        setIsLanguageChangeModalOpen(false);
        afterLanguageChangeCallback && afterLanguageChangeCallback();
    };
    // adding event listener to footer element
    useEffect(function () {
        var openLanguageSelector = function () {
            setIsLanguageChangeModalOpen(true);
        };
        var footerLanguageSwitcher = document.getElementById("language-change-switcher-footer");
        if (footerLanguageSwitcher && setFooterEventListener)
            footerLanguageSwitcher.addEventListener("click", openLanguageSelector);
        return function () {
            try {
                if (footerLanguageSwitcher && setFooterEventListener)
                    footerLanguageSwitcher.removeEventListener("click", openLanguageSelector);
            }
            catch (_a) { }
        };
    }, [setFooterEventListener]);
    var selectedLanguage = languages.find(function (language) {
        return language.id === currentLocale;
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "ducati-fixed-language-selector" }, { children: _jsxs("button", __assign({ "data-testid": "language-selector", onClick: function () { return setIsLanguageChangeModalOpen(true); }, className: "ducati-fixed-language-selector__language-button" }, { children: [(ducatiLanguages === null || ducatiLanguages === void 0 ? void 0 : ducatiLanguages[selectedLanguage === null || selectedLanguage === void 0 ? void 0 : selectedLanguage.value]) ? ducatiLanguages === null || ducatiLanguages === void 0 ? void 0 : ducatiLanguages[selectedLanguage === null || selectedLanguage === void 0 ? void 0 : selectedLanguage.value] : selectedLanguage === null || selectedLanguage === void 0 ? void 0 : selectedLanguage.label, _jsx(DownOutlined, { className: "ducati-fixed-language-selector__language-button-icon" })] })) })), _jsx(Modal, __assign({ transitionName: "", onCancel: function () { return setIsLanguageChangeModalOpen(false); }, className: baseClassModal, title: ducatiContents.changeLanguageModalTitle, open: isLanguageChangeModalOpen, footer: null, styles: { content: { borderRadius: 0 } } }, { children: _jsx("nav", { children: _jsx("ul", __assign({ className: "".concat(baseClassModal, "__language-options") }, { children: languages.map(function (language) {
                            var _a;
                            return (_jsx("li", __assign({ className: classNames("ducati-fixed-language-selector-modal__language-option", (_a = {},
                                    _a["selected"] = language.value === currentLocale,
                                    _a)) }, { children: _jsx("a", __assign({ onClick: function (e) { return handleLangChange(e, language.value); }, rel: "alternate", href: "#", hrefLang: language.value, lang: language.value, className: "".concat(baseClassModal, "__language-change-link") }, { children: (ducatiLanguages === null || ducatiLanguages === void 0 ? void 0 : ducatiLanguages[language === null || language === void 0 ? void 0 : language.value]) ? ducatiLanguages === null || ducatiLanguages === void 0 ? void 0 : ducatiLanguages[language === null || language === void 0 ? void 0 : language.value] : language === null || language === void 0 ? void 0 : language.label })) }), language.id));
                        }) })) }) }))] }));
};
