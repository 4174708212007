var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export var DucatiProfileIconSvg = function () {
    return (_jsx("svg", __assign({ version: "1.1", id: "Livello_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 48 48", xmlSpace: "preserve" }, { children: _jsxs("g", { children: [_jsx("path", { d: "M24,25.2579c-3.8599,0-7-3.1401-7-7s3.1401-7,7-7s7,3.1401,7,7S27.8599,25.2579,24,25.2579z M24,13.2579\n\t\tc-2.7568,0-5,2.2432-5,5s2.2432,5,5,5s5-2.2432,5-5S26.7568,13.2579,24,13.2579z" }), _jsx("path", { d: "M24,26.2579c-3.2283,0-8.1212,5.8337-11.1855,9.9697c0.1543,0.173,0.3055,0.3487,0.4676,0.5144\n\t\tc2.6942-2.5253,6.7025-5.4841,10.7179-5.4841s8.0237,2.9588,10.7179,5.4841c0.162-0.1657,0.3134-0.3415,0.4677-0.5145\n\t\tC32.1213,32.0916,27.2282,26.2579,24,26.2579z" })] }) })));
};
