var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal as AntModal } from "antd";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectAppContent } from "@adm-media/adam-store-plugin";
import Divider from "../../../ui/Divider/Divider";
import { SanitizeHtml } from "../../../ui/SanitizeHtml/SanitizeHtml";
import Space from "../../../ui/Space/Space";
import "./DucatiModal.scss";
var DucatiModal = function (_a) {
    var _b = _a.destroyModal, destroyModal = _b === void 0 ? true : _b, _c = _a.destroyOnClose, destroyOnClose = _c === void 0 ? true : _c, _d = _a.footer, footer = _d === void 0 ? null : _d, children = _a.children, props = __rest(_a, ["destroyModal", "destroyOnClose", "footer", "children"]);
    var contents = useSelector(selectAppContent);
    var getModalFooter = function () {
        if (!!contents.default_modal_footer && footer === null)
            return (_jsxs(_Fragment, { children: [_jsx(Divider, {}), _jsx(SanitizeHtml, { stringToSanitize: contents.default_modal_footer, tag: "div" })] }));
        if (!contents.default_modal_footer && footer === null)
            return null;
        if (!contents.default_modal_footer && footer)
            return _jsx("div", __assign({ style: { paddingTop: 10 } }, { children: footer }));
        if (contents.default_modal_footer && footer)
            return (_jsx(_Fragment, { children: _jsxs(Space, __assign({ direction: "vertical", style: { width: "100%" } }, { children: [_jsx("div", __assign({ style: { paddingTop: 10 } }, { children: footer })), _jsx(Divider, {}), _jsx(SanitizeHtml, { stringToSanitize: contents.default_modal_footer, tag: "div" })] })) }));
    };
    return (_jsx(AntModal, __assign({ transitionName: "", destroyOnClose: destroyModal ? destroyModal : destroyOnClose, footer: getModalFooter(), style: props.bodyStyle, className: "default-modal ducati-modal ".concat(props.modalClassName), keyboard: false, centered: true }, props, { children: _jsx("div", __assign({ "data-testid": "modal-body", className: classNames(props.modalBodyClassName) }, { children: children })) })));
};
export default DucatiModal;
