var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from "../../ui/Form/Form";
import { useSelector } from "react-redux";
import { passwordResetThunk, selectAppContent, selectPasswordReset } from "@adm-media/adam-store-plugin";
import Col from "../../ui/Col/Col";
import Input from "../../ui/Input/Input";
import Button from "../../ui/Buttons/Button";
import Typography from "../../ui/Typography/Typography";
import { useAppDispatch } from "../../../store";
import { useNotification } from "../../../hooks/useNotification";
import { mapErrorsToFields } from "../../../utils/map-errors-to-fields";
import { useGetDucatiContents } from "../../project-specific/ducati/contents/useGetDucatiContents";
var Item = Form.Item;
var Password = Input.Password;
var Title = Typography.Title;
export var ResetPassword = function (_a) {
    var email = _a.email, token = _a.token, onSuccessCallback = _a.onSuccessCallback;
    var dispatch = useAppDispatch();
    var form = Form.useForm()[0];
    var notification = useNotification().notification;
    var ducatiContents = useGetDucatiContents();
    var loadingPasswordReset = useSelector(selectPasswordReset).loading;
    var contents = useSelector(selectAppContent);
    var handleResetPassword = function (values) {
        values = __assign(__assign({}, values), { email: email, token: token });
        dispatch(passwordResetThunk(values))
            .unwrap()
            .then(function () {
            notification.showGenericSuccess();
            onSuccessCallback && onSuccessCallback();
        })
            .catch(function (error) {
            if ((error === null || error === void 0 ? void 0 : error.status) === 422) {
                try {
                    var valuesWithErrors = mapErrorsToFields({
                        error: error.data,
                        values: {
                            password: values === null || values === void 0 ? void 0 : values.password,
                            password_confirmation: values === null || values === void 0 ? void 0 : values.password_confirmation
                        }
                    });
                    form.setFields(valuesWithErrors);
                }
                catch (_a) { }
                notification.showGenericError();
            }
            else {
                notification.showGenericError();
                form.resetFields();
            }
        });
    };
    return (_jsxs(Col, __assign({ xs: 24 }, { children: [_jsx(Title, __assign({ level: 3 }, { children: contents.reset_password })), _jsxs(Form, __assign({ form: form, onFinish: handleResetPassword, layout: "vertical" }, { children: [_jsx(Item, __assign({ name: "password", label: contents.password || "Password", rules: [
                            {
                                required: true,
                                message: contents.field_required
                            }
                        ], extra: _jsx("div", __assign({ style: { paddingTop: 5 } }, { children: ducatiContents.passwordMinimumRequirements })) }, { children: _jsx(Password, { placeholder: contents.password || "Password" }) })), _jsx(Item, __assign({ name: "password_confirmation", label: contents.password_confirmation || "Confirm password", dependencies: ["password"], rules: [
                            {
                                required: true,
                                message: contents === null || contents === void 0 ? void 0 : contents.error_password_required
                            },
                            function (_a) {
                                var getFieldValue = _a.getFieldValue;
                                return ({
                                    validator: function (_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error());
                                    }
                                });
                            }
                        ] }, { children: _jsx(Password, { type: "password", placeholder: contents.password_confirmation || "Confirm password" }) })), _jsx(Button, __assign({ "data-testid": "reset-password-submit-button", type: "primary", loading: loadingPasswordReset === "pending", htmlType: "submit" }, { children: contents.reset_password || "Reset password" }))] }))] })));
};
