import moment from "moment";
import { utcToTimeZone } from "./timezone";
import { convertPHPToMomentFormat } from "./date";
var dateFormat = "YYYY-MM-DD HH:mm:ss";
export var getDucatiConventionDate = function (_a) {
    var _b;
    var date = _a.date, timezone = _a.timezone, timeFormatType = _a.timeFormatType, dateFormatType = _a.dateFormatType, locale = _a.locale;
    var timezoneDateTime = utcToTimeZone({ time: date, zone: timezone, format: dateFormat });
    if (!timezoneDateTime)
        return null;
    if (!timeFormatType || !dateFormatType)
        return timezoneDateTime;
    try {
        var liveDate = ((_b = timezoneDateTime.split(" ")) === null || _b === void 0 ? void 0 : _b[0]) || "";
        var formattedLiveDate = timezoneDateTime
            ? "".concat(moment(liveDate)
                .locale(locale || "en")
                .format(convertPHPToMomentFormat(dateFormatType)))
            : null;
        return formattedLiveDate;
    }
    catch (_c) {
        return null;
    }
};
export var getDucatiConventionTime = function (_a) {
    var date = _a.date, timezone = _a.timezone, timeFormatType = _a.timeFormatType, dateFormatType = _a.dateFormatType, dsTimezoneAbbreviation = _a.dsTimezoneAbbreviation, timezoneAbbreviation = _a.timezoneAbbreviation;
    var timezoneDateTime = utcToTimeZone({ time: date, zone: timezone, format: dateFormat });
    if (!timezoneDateTime)
        return null;
    if (!timeFormatType || !dateFormatType)
        return timezoneDateTime;
    try {
        var formattedLiveTime = timezoneDateTime
            ? "".concat(moment(timezoneDateTime).format(convertPHPToMomentFormat(timeFormatType)), " ").concat(moment.tz(timezoneDateTime, timezone || "utc").isDST()
                ? dsTimezoneAbbreviation || timezoneAbbreviation || ""
                : timezoneAbbreviation || "")
            : null;
        return formattedLiveTime;
    }
    catch (_b) {
        return null;
    }
};
