export var ducatiNationalMeetings = {
    mx: {
        descriptionText: "Ducati North America (Mexico) - October 23<sup>rd</sup> - 21:00-23:00 CEST",
        buttonLink: "https://teams.microsoft.com/l/meetup-join/19%3ameeting_YTExYTEyMGQtMGM3Yi00MTA2LTk4YzEtZDlhMmUyYWI2OWJm%40thread.v2/0?context=%7b%22Tid%22%3a%22c5b5c68a-a68a-4771-acc4-d8d03b17b2e5%22%2c%22Oid%22%3a%229c5a5d9f-2fea-4b13-9415-b90f021c8c18%22%7d",
        videoUrl: undefined
    },
    us: {
        descriptionText: "Ducati North America (US-CN) - October 23<sup>rd</sup> - 19:00-21:00 CEST",
        buttonLink: undefined,
        videoUrl: "https://assets.ducatigdc2024.com/videos/national-meetings/gdc2024_dna.mp4"
    },
    pr: {
        descriptionText: "Ducati North America (US-CN) - October 23<sup>rd</sup> - 19:00-21:00 CEST",
        buttonLink: undefined,
        videoUrl: undefined
    },
    ca: {
        descriptionText: "Ducati North America (US-CN) - October 23<sup>rd</sup> - 19:00-21:00 CEST",
        buttonLink: undefined,
        videoUrl: undefined
    },
    it: {
        descriptionText: "Ducati Italia - October 25<sup>th</sup> - 09:30-12:30 CEST",
        buttonLink: undefined,
        videoUrl: undefined
    },
    nl: {
        descriptionText: "Ducati West Europe (Benelux) - October 25<sup>th</sup> - 09:00-12:30 CEST",
        buttonLink: "https://app.sli.do/event/oLrQPg3DKgrrKFMycqUox7/live/questions",
        videoUrl: undefined
    },
    be: {
        descriptionText: "Ducati West Europe (Benelux) - October 25<sup>th</sup> - 09:00-12:30 CEST",
        buttonLink: "https://app.sli.do/event/oLrQPg3DKgrrKFMycqUox7/live/questions",
        videoUrl: undefined
    },
    lu: {
        descriptionText: "Ducati West Europe (Benelux) - October 25<sup>th</sup> - 09:00-12:30 CEST",
        buttonLink: "https://app.sli.do/event/oLrQPg3DKgrrKFMycqUox7/live/questions",
        videoUrl: undefined
    },
    fr: {
        descriptionText: "Ducati West Europe (France) - October 23<sup>rd</sup> - 09:00-12:30 CEST",
        buttonLink: "https://app.sli.do/event/oLrQPg3DKgrrKFMycqUox7/live/questions",
        videoUrl: undefined
    }
};
