var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { DucatiHeroTitle } from "../DucatiHeroTitle/DucatiHeroTitle";
import "./DucatiVirtualTour.scss";
import classNames from "classnames";
import { getProtectedAssetUrl, VIDEO_MUSEUM, VIDEO_MUSEUM_POSTER, VIDEO_TOUR, VIDEO_TOUR_POSTER } from "../../../../utils/assets";
import { useSelector } from "react-redux";
import { selectLocaleLanguage } from "@adm-media/adam-store-plugin";
import { useGetDucatiContents } from "../contents/useGetDucatiContents";
export var DucatiVirtualTour = function () {
    var baseClass = "ducati-virtual-tour";
    var currentLocale = useSelector(selectLocaleLanguage);
    var ducatiContents = useGetDucatiContents();
    var _a = useState(false), mustUpdateSources = _a[0], setMustUpdateSources = _a[1];
    var _b = useState([
        { src: getProtectedAssetUrl(VIDEO_TOUR, currentLocale), poster: getProtectedAssetUrl(VIDEO_TOUR_POSTER) },
        { src: getProtectedAssetUrl(VIDEO_MUSEUM, currentLocale), poster: getProtectedAssetUrl(VIDEO_MUSEUM_POSTER) }
    ]), videoAssets = _b[0], setVideoAssets = _b[1];
    useEffect(function () {
        setMustUpdateSources(true);
    }, [currentLocale]);
    useEffect(function () {
        if (mustUpdateSources === true) {
            setVideoAssets([
                { src: getProtectedAssetUrl(VIDEO_TOUR, currentLocale), poster: getProtectedAssetUrl(VIDEO_TOUR_POSTER) },
                { src: getProtectedAssetUrl(VIDEO_MUSEUM, currentLocale), poster: getProtectedAssetUrl(VIDEO_MUSEUM_POSTER) }
            ]);
            setMustUpdateSources(false);
        }
    }, [mustUpdateSources, currentLocale]);
    return (_jsxs("div", __assign({ className: baseClass, "data-testid": "ducati-virtual-tour-section" }, { children: [_jsx(DucatiHeroTitle, { title: ducatiContents.virtualTourTitle, subtitle: ducatiContents.virtualTourBody }), _jsx("div", __assign({ className: classNames("d-grid-imgtext", "".concat(baseClass, "__videos-container")) }, { children: _jsx("div", __assign({ className: "body" }, { children: _jsx("div", __assign({ className: "".concat(baseClass, "__videos-wrapper") }, { children: !mustUpdateSources &&
                            videoAssets.map(function (videoAsset) {
                                return (_jsx("div", { children: _jsxs("video", __assign({ playsInline: true, controls: true, poster: videoAsset.poster, className: "".concat(baseClass, "__video") }, { children: [_jsx("source", { src: videoAsset.src, type: "video/mp4" }), "Your browser does not support HTML video."] })) }, videoAsset.src));
                            }) })) })) }))] })));
};
