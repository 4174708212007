var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { logoutUserThunk, selectAppContent, selectCurrentDrawer, selectMedia, selectStyles, toggleDrawer } from "@adm-media/adam-store-plugin";
import "./DucatiWatchNavbar.scss";
import { useAppDispatch } from "../../../../store";
import { DucatiProfileFlyout } from "../DucatiProfileFlyout/DucatiProfileFlyout";
import { DucatiFixedLanguageSelector } from "../DucatiFixedLanguageSelector/DucatiFixedLanguageSelector";
import { DucatiNotifications } from "../DucatiNotifications/DucatiNotifications";
import { DucatiProfileIcon } from "../DucatiIcons/DucatiIcons";
import { Button } from "antd";
import { SUBPAGES } from "../../../../pages/Watch/Watch";
import { useNavigate } from "react-router-dom";
import { useGetDucatiContents } from "../contents/useGetDucatiContents";
export var DucatiWatchNavbar = function () {
    var _a;
    var navigate = useNavigate();
    var _b = useSelector(selectMedia), header_logo_desktop = _b.header_logo_desktop, header_logo_mobile = _b.header_logo_mobile;
    var styles = useSelector(selectStyles);
    var dispatch = useAppDispatch();
    var isDrawerOpen = useSelector(selectCurrentDrawer);
    var contents = useSelector(selectAppContent);
    var ducatiContents = useGetDucatiContents();
    var baseClass = "ducati-watch-navbar";
    var handleLogoutUser = function () {
        dispatch(logoutUserThunk());
    };
    var burgerButtonClass = classNames("".concat(baseClass, "__burger-button"), (_a = {}, _a["".concat(baseClass, "__burger-button--open")] = isDrawerOpen, _a));
    return (_jsx("div", __assign({ className: baseClass }, { children: _jsxs("div", __assign({ className: "".concat(baseClass, "__container") }, { children: [_jsx("button", __assign({ onClick: function () { return navigate("#".concat(SUBPAGES.home)); }, className: "".concat(baseClass, "__home-button"), "data-testid": "convention-back-to-home-button" }, { children: ducatiContents.homeLabel })), _jsxs("div", __assign({ className: "".concat(baseClass, "__logo-container") }, { children: [_jsx("div", __assign({ className: "".concat(baseClass, "__logo ").concat(baseClass, "__logo--desktop") }, { children: _jsx("img", { src: header_logo_desktop === null || header_logo_desktop === void 0 ? void 0 : header_logo_desktop.url, alt: "Logo" }) })), _jsx("div", __assign({ className: "".concat(baseClass, "__logo ").concat(baseClass, "__logo--mobile") }, { children: _jsx("img", { src: header_logo_mobile === null || header_logo_mobile === void 0 ? void 0 : header_logo_mobile.url, alt: "Logo" }) }))] })), _jsxs("div", __assign({ className: "".concat(baseClass, "__actions-container") }, { children: [_jsx(DucatiNotifications, {}), _jsx(DucatiProfileFlyout, __assign({ flyoutContent: _jsxs(_Fragment, { children: [_jsx(DucatiFixedLanguageSelector, { setFooterEventListener: true }), _jsx("button", __assign({ onClick: handleLogoutUser, "data-testid": "logout-button", className: "".concat(baseClass, "__logout-button") }, { children: contents.logout }))] }) }, { children: _jsx(Button, { className: "".concat(baseClass, "__profile-button"), title: "profile", icon: _jsx(DucatiProfileIcon, {}) }) })), _jsxs("button", __assign({ role: "menu", "data-testid": "navbar-hamburger-icon", className: burgerButtonClass, onClick: function () { return dispatch(toggleDrawer()); } }, { children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] }))] }))] })) })));
};
