import { PusherMock } from "pusher-js-mock";
export var subscribeToPusherIdChannel = function (_a) {
    var pusherSingleton = _a.pusherSingleton, _b = _a.config, token = _b.token, appId = _b.appId, channelId = _b.channelId;
    if (window === null || window === void 0 ? void 0 : window.Cypress) {
        var pusherInstance = new PusherMock();
        fetch(process.env.PUSHER_BASE_URL + "/broadcasting/auth/id-channel", { method: "POST" });
        var channel = pusherInstance.subscribe("presence-".concat(appId, ".123456789"));
        channel.unsubscribe = function () { };
        return channel;
    }
    else {
        try {
            if (pusherSingleton) {
                var channel = pusherSingleton.subscribe("presence-".concat(appId, ".").concat(channelId));
                return channel;
            }
            else {
                return null;
            }
        }
        catch (_c) {
            console.warn("Error in pusher connection / channel id");
            return null;
        }
    }
};
