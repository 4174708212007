var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
export var DucatiQandAIconSvg = function () {
    return (_jsx("svg", __assign({ version: "1.1", id: "Livello_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 48 48", xmlSpace: "preserve" }, { children: _jsx("path", { d: "M23.3176,13.0003c-1.4019,0.5007-2.7244,1.168-3.9302,2H14c-0.5522,0-1-0.4478-1-1s0.4478-1,1-1H23.3176z M14,18.0003\n\tc-0.5522,0-1,0.4478-1,1s0.4478,1,1,1h0.616c0.4407-0.7018,0.9243-1.3718,1.4592-2H14z M9.0544,31.9997\n\tc0.2657-0.2649,0.549-0.548,0.8511-0.8511l1.4084-1.4125l-1.4088-1.4122c-0.0737-0.0739-0.1649-0.16-0.2595-0.249\n\tc-0.08-0.0751-0.1656-0.1522-0.2247-0.2168C7.2148,25.4474,6,22.3163,6,19.0414c0-7.1909,5.8318-13.0411,13-13.0411\n\tc4.6025,0,8.6442,2.4185,10.9548,6.0485c0.8364,0.047,1.6625,0.1334,2.4626,0.2977C29.9596,7.4037,24.8823,4.0003,19,4.0003\n\tc-8.2843,0-15,6.7341-15,15.0411c0,3.9199,1.4955,7.4896,3.9454,10.1669c0.1459,0.1593,0.3912,0.3752,0.5438,0.5282\n\tc-3.9922,4.0039-4.4347,4.2513-4.4822,4.2632c0.1785,0,4.627,0,8.7329,0c-0.1982-0.6498-0.3459-1.3199-0.4658-2H9.0544z\n\t M40.0546,39.2082C42.5045,36.531,44,32.9614,44,29.0415c0-8.3071-6.7157-15.0413-15-15.0413s-15,6.7341-15,15.0413\n\tc0,8.3069,6.5977,14.9583,14.882,14.9583c2.684,0,14.8138,0,15.111,0c-0.0475-0.012-0.49-0.2593-4.4822-4.2632\n\tC39.6633,39.5835,39.9087,39.3676,40.0546,39.2082z M22,31.0002c-1.1045,0-2-0.8954-2-2c0-1.1045,0.8955-2,2-2s2,0.8955,2,2\n\tC24,30.1048,23.1045,31.0002,22,31.0002z M29,31.0002c-1.1045,0-2-0.8954-2-2c0-1.1045,0.8955-2,2-2s2,0.8955,2,2\n\tC31,30.1048,30.1045,31.0002,29,31.0002z M36,31.0002c-1.1045,0-2-0.8954-2-2c0-1.1045,0.8955-2,2-2s2,0.8955,2,2\n\tC38,30.1048,37.1045,31.0002,36,31.0002z" }) })));
};
