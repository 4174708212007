var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ducatiFooterLogo from "../assets/ducati-footer-logo.svg";
import { useSelector } from "react-redux";
import { selectAppContent, selectAppLanguages, selectLocaleLanguage, selectMedia } from "@adm-media/adam-store-plugin";
import { SanitizeHtml } from "../../../ui/SanitizeHtml/SanitizeHtml";
import { UpOutlined } from "@ant-design/icons";
import "./DucatiFooter.scss";
import { ducatiLanguages } from "../contents/ducatiContents";
export var DucatiFooter = function (_a) {
    var _b, _c;
    var hideLanguageSelector = _a.hideLanguageSelector;
    var contents = useSelector(selectAppContent);
    var media = useSelector(selectMedia);
    var currentLocale = useSelector(selectLocaleLanguage);
    var languages = useSelector(selectAppLanguages);
    // const legals = useSelector(selectRegistrationFormLegals);
    var selectedLanguage = languages.find(function (language) {
        return language.id === currentLocale;
    });
    // const footerLegals = legals.filter((legal) => !!!legal.is_checkbox);
    var footerLegals = [
        {
            label: "TERMS OF USE",
            href: "https://www.ducati.com/ww/en/home/terms"
        },
        {
            label: "PRIVACY INFORMATION",
            href: "https://www.ducati.com/gb/en/home/privacy"
        },
        {
            label: "COOKIES INFORMATION",
            href: "https://www.ducati.com/gb/en/home/cookie-policy"
        }
    ];
    return (_jsx("div", __assign({ "data-testid": "ducati-footer", className: "d-footer-main ducati-footer-section", "data-js-footer-main": true }, { children: _jsxs("section", __assign({ className: "body" }, { children: [_jsxs("div", __assign({ className: "logo" }, { children: [_jsx("img", { src: ducatiFooterLogo, alt: "DUCATI", itemProp: "logo" }), _jsx("img", { src: "https://assets.ducatigdc2024.com/images/pixel.png", style: { display: "none" }, alt: "pixel" })] })), _jsx("div", __assign({ className: "links-wrapper iscroll" }, { children: _jsx("ul", __assign({ className: "links" }, { children: footerLegals.map(function (legal) {
                            return (_jsx("li", __assign({ className: "item", "data-testid": "footer-legal-list-item" }, { children: _jsx("a", __assign({ className: "link", rel: "noreferrer", target: "_blank", href: legal.href }, { children: legal.label })) }), legal.label));
                        }) })) })), _jsxs("div", __assign({ className: "copyright" }, { children: [_jsx(SanitizeHtml, { stringToSanitize: contents.website_footer, tag: "div" }), _jsx("div", { children: (((_b = media.footer_logo_desktop) === null || _b === void 0 ? void 0 : _b.url) || contents.footer_logo_text) && (_jsxs("div", __assign({ className: "powered-by-section" }, { children: [_jsx("br", {}), contents.footer_logo_text && _jsx("div", __assign({ style: { paddingBottom: 10 } }, { children: contents.footer_logo_text })), " ", _jsx("img", { src: (_c = media.footer_logo_desktop) === null || _c === void 0 ? void 0 : _c.url, alt: "" })] }))) })] })), _jsxs("div", __assign({ className: "flex" }, { children: [_jsx("div", { className: "box logos" }), _jsx("div", { className: "box socials-wrapper iscroll" }), _jsx("div", __assign({ className: "box languages" }, { children: _jsxs("a", __assign({ id: "language-change-switcher-footer", "data-testid": "language-change-switcher-footer", className: "country", rel: "modal:open", style: hideLanguageSelector ? { display: "none" } : undefined }, { children: [_jsx("span", __assign({ className: "label" }, { children: selectedLanguage && (ducatiLanguages === null || ducatiLanguages === void 0 ? void 0 : ducatiLanguages[selectedLanguage === null || selectedLanguage === void 0 ? void 0 : selectedLanguage.value])
                                            ? // @ts-expect-error ducatiLanguages being indexed by type string of language.label
                                                ducatiLanguages === null || ducatiLanguages === void 0 ? void 0 : ducatiLanguages[selectedLanguage === null || selectedLanguage === void 0 ? void 0 : selectedLanguage.value]
                                            : selectedLanguage === null || selectedLanguage === void 0 ? void 0 : selectedLanguage.label })), _jsx(UpOutlined, { style: { marginLeft: 10, transform: "scaleY(0.7)", marginBottom: 2 } })] })) }))] })), _jsx("div", __assign({ style: { width: 0, height: 0 } }, { children: _jsx("img", { style: { display: "none", width: 1, height: 1 }, src: "https://d2qfa4g4gsvvrj.cloudfront.net/adm-logo.jpg", alt: "" }) }))] })) })));
};
export default DucatiFooter;
