export var ducatiContentsObject = {
    en: {
        // general
        passwordMinimumRequirements: "Make sure that the password you enter meets the specified requirements: at least 8 characters, one upper case letter, one lower case letter, one number and one special character.",
        settingsLabel: "Settings",
        homeLabel: "Home",
        loginErrorMessage: "Login error",
        alreadyLoggedInTitle: "You are already logged in",
        alreadyLoggedInMessage: "Close active sessions before proceeding",
        alreadyLoggedInButtonLabel: "End active sessions",
        changePasswordModalTitle: "Hi {firstname} {lastname}, you can change your password below:",
        changeLanguageModalTitle: "Language",
        // home page
        heroConventionButtonLabel: "Convention",
        heroDownloadAgendaButtonLabel: "Download the full programme",
        videomessageSectionTitle: "Video message",
        videomessageSectionCardTitle: "Beyond the ordinary roads",
        videomessageSectionCardBody: "Welcome to GDC2024! Watch the video message from Francesco Milicia, VP Global Sales and After Sales.",
        videomessageSectionCardCTALabel: "Watch",
        agendaSectionTitle: "Agenda",
        agendaSectionBody: "The convention will start at {convention_time} on {convention_day}",
        agendaSectionDownloadButtonLabel: "Download the full programme",
        conferenceSectionTitle: "Virtual Tour",
        conferenceSectionConventionCardTitle: "Convention",
        conferenceSectionConventionCardBody: "Ducati's strategy and the latest on the 2025 range.",
        conferenceSectionNationalMeetingCardTitle: "National Meeting",
        conferenceSectionNationalMeetingCardBody: "New developments, objectives and strategy in your area.",
        conferenceSectionVirtualTourCardTitle: "Virtual Tour",
        conferenceSectionVirtualTourCardBody: "Take a virtual tour of the Ducati factory and museum.",
        digitalGiftCardTitle: "A gift for you",
        digitalGiftCardBody: "Download the commemorative wallpaper of the new Panigale V4.",
        // virtual tour page
        nationalMeetingTitle: "National Meeting",
        nationalMeetingBody: "A chance to share and discuss the latest developments, goals and strategy in your area.",
        // national meeting page
        virtualTourTitle: "Ducati Virtual Tour",
        virtualTourBody: "Learn about our past and experience the present by taking a virtual tour of the museum and factory.",
        welcomeTitle: "Hi {NAME}",
        welcomeBody: "\n        <p>we are pleased to welcome you to the Global Dealer Conference 2024! Over the next few days, you can look forward to the Convention, where we will share Ducati's strategy, review the results of the past year, and discover together the latest Ducati models.</p>\n        <p>You will also have the opportunity to virtually explore the Factory and Museum in Borgo Panigale to immerse yourself even more in the Ducati\u2019s world.</p>\n        <p>Have a great Global Dealer Conference!</p>"
    },
    fr: {
        // general
        passwordMinimumRequirements: "Assurez-vous que le mot de passe saisi répond aux critères suivants : au moins 8 caractères, une lettre majuscule et une lettre minuscule, un chiffre et un caractère spécial.",
        settingsLabel: "Paramètres",
        homeLabel: "Accueil",
        loginErrorMessage: "Erreur de connexion",
        alreadyLoggedInTitle: "Vous êtes déjà connecté(e)",
        alreadyLoggedInMessage: "Fermer les sessions actives avant de poursuivre",
        alreadyLoggedInButtonLabel: "Fermer les sessions actives",
        changePasswordModalTitle: "Bonjour {firstname} {lastname}, vous pouvez changer de mot de passe ci-dessous",
        changeLanguageModalTitle: "Langue",
        // home page
        heroConventionButtonLabel: "Convention",
        heroDownloadAgendaButtonLabel: "Télécharger le programme complet",
        videomessageSectionTitle: "Message vidéo",
        videomessageSectionCardTitle: "Beyond the ordinary roads",
        videomessageSectionCardBody: "Bienvenue à la GDC 2024! Regardez le message vidéo de Francesco Milicia, VP Global Sales and After Sales.",
        videomessageSectionCardCTALabel: "Regarder",
        agendaSectionTitle: "Agenda",
        agendaSectionBody: "La convention débutera à {convention_time} le {convention_day}",
        agendaSectionDownloadButtonLabel: "Télécharger le programme complet",
        conferenceSectionTitle: "Visite virtuelle",
        conferenceSectionConventionCardTitle: "Convention",
        conferenceSectionConventionCardBody: "La stratégie de Ducati et un avant-goût de la gamme 2025.",
        conferenceSectionNationalMeetingCardTitle: "Réunion Nationale",
        conferenceSectionNationalMeetingCardBody: "Nouveautés, objectifs et stratégie pour votre pays",
        conferenceSectionVirtualTourCardTitle: "Visite virtuelle",
        conferenceSectionVirtualTourCardBody: "Participez à la visite virtuelle de l'usine et du musée Ducati.",
        digitalGiftCardTitle: "Un cadeau pour vous",
        digitalGiftCardBody: "Téléchargez le fond d'écran souvenir de la nouvelle Panigale V4.",
        // virtual tour page
        nationalMeetingTitle: "Réunion Nationale",
        nationalMeetingBody: "L'occasion de partager et de discuter des nouveautés, des objectifs et de la stratégie dans votre pays.",
        // national meeting page
        virtualTourTitle: "Visite virtuelle Ducati",
        virtualTourBody: "Découvrez le passé de la marque et vivez le présent en participant à la visite virtuelle du musée et de l'usine.",
        welcomeTitle: "Bonjour {NAME}",
        welcomeBody: "\n        <p>Nous sommes ravis de vous souhaiter la bienvenue \u00E0 la Global Dealer Conference 2024 ! </p>\n        <p>Dans les prochains jours, des \u00E9v\u00E9nements marquants vous attendent. Pr\u00E9parez-vous pour la convention, o\u00F9 nous partagerons les choix strat\u00E9giques de Ducati, les r\u00E9sultats de l'ann\u00E9e \u00E9coul\u00E9e et d\u00E9couvrirons ensemble les derni\u00E8res nouveaut\u00E9s de la gamme Ducati.</p>\n        <p>Vous aurez \u00E9galement l'opportunit\u00E9 d'explorer virtuellement le mus\u00E9e et l'usine de Borgo Panigale pour vous immerger encore plus dans le monde de Ducati.</p>\n        <p>Nous vous souhaitons une bonne Global Dealer Conference !</p>"
    },
    it: {
        // general
        passwordMinimumRequirements: "Assicurati che la password inserita rispetti i requisiti indicati: almeno 8 caratteri, una lettera maiuscola, una minuscola, un numero e un carattere speciale.",
        settingsLabel: "Impostazioni",
        homeLabel: "Home",
        loginErrorMessage: "Errore di accesso",
        alreadyLoggedInTitle: "Hai già effettuato l'accesso",
        alreadyLoggedInMessage: "Chiudi le sessioni attive prima di procedere",
        alreadyLoggedInButtonLabel: "Chiudi le sessioni attive",
        changePasswordModalTitle: "Ciao {firstname} {lastname}, puoi cambiare la tua password di seguito:",
        changeLanguageModalTitle: "Lingua",
        // home page
        heroConventionButtonLabel: "Convention",
        heroDownloadAgendaButtonLabel: "Scarica il programma completo",
        videomessageSectionTitle: "Videomessaggio",
        videomessageSectionCardTitle: "Beyond the ordinary roads",
        videomessageSectionCardBody: "Benvenuto alla GDC2024! Guarda il video messaggio di Francesco Milicia, VP Global Sales and After Sales.",
        videomessageSectionCardCTALabel: "Guarda",
        agendaSectionTitle: "Agenda",
        agendaSectionBody: "La convention comincerà alle {convention_time} del {convention_day}",
        agendaSectionDownloadButtonLabel: "Scarica il programma completo",
        conferenceSectionTitle: "Virtual Tour",
        conferenceSectionConventionCardTitle: "Convention",
        conferenceSectionConventionCardBody: "La strategia Ducati e le anticipazioni della gamma 2025.",
        conferenceSectionNationalMeetingCardTitle: "National Meeting",
        conferenceSectionNationalMeetingCardBody: "Novità, obiettivi e strategia della tua area.",
        conferenceSectionVirtualTourCardTitle: "Virtual Tour",
        conferenceSectionVirtualTourCardBody: "Partecipa al tour virtuale della fabbrica e del Museo Ducati.",
        digitalGiftCardTitle: "Un pensiero per te",
        digitalGiftCardBody: "Scarica il wallpaper commemorativo della nuova Panigale V4.",
        // virtual tour page
        nationalMeetingTitle: "National Meeting",
        nationalMeetingBody: "L’occasione per condividere e discutere novità, obiettivi e strategia della tua area.",
        // national meeting page
        virtualTourTitle: "Ducati Virtual Tour",
        virtualTourBody: "Scopri il nostro passato e vivi il presente partecipando al tour virtuale di museo e fabbrica.",
        welcomeTitle: "Ciao {NAME}",
        welcomeBody: "\n        <p>siamo lieti di darti il benvenuto alla Global Dealer Conference 2024! Nei prossimi giorni ti attendono la Convention, dove verranno condivise le scelte strategiche di Ducati, i risultati dell'anno appena trascorso e scopriremo insieme le ultime novit\u00E0 della gamma Ducati.</p>\n        <p>Avrai anche l'opportunit\u00E0 di esplorare virtualmente il museo e la fabbrica di Borgo Panigale per immergerti ancora di pi\u00F9 nel mondo Ducati.</p>\n        <p>Buona Global Dealer Conference!</p>"
    },
    de: {
        // general
        passwordMinimumRequirements: "Vergewissern Sie sich, dass das Passwort den Vorgaben entspricht: mindestens 8 Zeichen, ein Groß- und ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen.",
        settingsLabel: "Einstellungen",
        homeLabel: "Home",
        loginErrorMessage: "Fehler beim Zugriff",
        alreadyLoggedInTitle: "Sie sind bereits angemeldet.",
        alreadyLoggedInMessage: "Schließen Sie alle aktiven Sitzungen, bevor Sie fortfahren.",
        alreadyLoggedInButtonLabel: "Schließen Sie alle aktiven Sitzungen.",
        changePasswordModalTitle: "Hallo {firstname} {lastname}, nachfolgend können Sie Ihr Passwort ändern:",
        changeLanguageModalTitle: "Sprache",
        // home page
        heroConventionButtonLabel: "Kongress",
        heroDownloadAgendaButtonLabel: "Download Event Programm",
        videomessageSectionTitle: "Videonachricht",
        videomessageSectionCardTitle: "Beyond the ordinary roads",
        videomessageSectionCardBody: "Willkommen bei der GDC2024! Schauen Sie sich die Videonachricht von Francesco Milicia, VP Global Sales and After Sales, an.",
        videomessageSectionCardCTALabel: "Ansehen",
        agendaSectionTitle: "Agenda",
        agendaSectionBody: "Die Tagung beginnt um {convention_time} am {convention_day}",
        agendaSectionDownloadButtonLabel: "Download Event Programm",
        conferenceSectionTitle: "Virtueller Rundgang",
        conferenceSectionConventionCardTitle: "Kongress",
        conferenceSectionConventionCardBody: "Die Strategie von Ducati und die Vorschau auf die Modellreihe für 2025.",
        conferenceSectionNationalMeetingCardTitle: "Nationales Meeting",
        conferenceSectionNationalMeetingCardBody: "Neuigkeiten, Ziele und Strategien in Ihrem Bereich.",
        conferenceSectionVirtualTourCardTitle: "Virtueller Rundgang",
        conferenceSectionVirtualTourCardBody: "Nehmen Sie am virtuellen Rundgang durch das Ducati-Werk und das Museum teil.",
        digitalGiftCardTitle: "Ein Geschenk für Dich",
        digitalGiftCardBody: "Lade Dir das spezielle Hintergrundbild der neuen Panigale V4 herunter.",
        // virtual tour page
        nationalMeetingTitle: "Nationales Meeting",
        nationalMeetingBody: "Die Gelegenheit zum Austausch und zur Diskussion von Neuigkeiten, Zielen und Strategien in Ihrem Bereich.",
        // national meeting page
        virtualTourTitle: "Virtueller Rundgang durch das Ducati-Museum",
        virtualTourBody: "Entdecken Sie unsere Vergangenheit und erleben Sie die Gegenwart, indem Sie an einem virtuellen Rundgang durch das Museum und das Werk teilnehmen.",
        welcomeTitle: "Ciao {NAME}",
        welcomeBody: "\n        <p>es freut uns, Sie zur Global Dealer Conference 2024 willkommen zu hei\u00DFen! In den n\u00E4chsten Tagen steht die Konferenz bevor. Dort werden wir die strategische Ausrichtung von Ducati sowie die Ergebnisse des vergangenen Jahres bekannt geben und wir werden gemeinsam die aktuellsten Neuheiten der Ducati Produktpalette entdecken.</p>\n        <p>Sie werden auch die M\u00F6glichkeit haben, virtuell das Museum und das Werk in Borgo Panigale zu besichtigen, um noch tiefer in die Ducati Welt einzutauchen.</p>\n        <p>Viel Spa\u00DF auf der Ducati Global Dealer Conference!</p>"
    },
    zh: {
        // general
        passwordMinimumRequirements: "请确保您输入的密码符合规定要求：至少 8 位，至少包含一个大写字母、一个小写字母、一个数字和一个特殊字符。",
        settingsLabel: "设置",
        homeLabel: "主页",
        loginErrorMessage: "登陆错误",
        alreadyLoggedInTitle: "您已经登录",
        alreadyLoggedInMessage: "在继续之前，关闭激活的会话",
        alreadyLoggedInButtonLabel: "关闭激活的会话",
        changePasswordModalTitle: "您好，{lastname} {firstname}，您可以在下面更改你的密码：",
        changeLanguageModalTitle: "语言",
        // home page
        heroConventionButtonLabel: "大会",
        heroDownloadAgendaButtonLabel: "下载完整方案",
        videomessageSectionTitle: "视频提示",
        videomessageSectionCardTitle: "Beyond the ordinary roads",
        videomessageSectionCardBody: "欢迎来到 GDC2024！请观看全球销售和售后副总裁 Francesco Milicia 的视频致辞。",
        videomessageSectionCardCTALabel: "观看",
        agendaSectionTitle: "议程",
        agendaSectionBody: "大会将于 {convention_day} 的 {convention_time} 开始",
        agendaSectionDownloadButtonLabel: "下载完整方案",
        conferenceSectionTitle: "数字之旅",
        conferenceSectionConventionCardTitle: "大会",
        conferenceSectionConventionCardBody: "杜卡迪的战略以及对 2025 年产品的展望。",
        conferenceSectionNationalMeetingCardTitle: "国家区域会议",
        conferenceSectionNationalMeetingCardBody: "您所在区域的新闻、目标及战略。",
        conferenceSectionVirtualTourCardTitle: "数字之旅",
        conferenceSectionVirtualTourCardBody: "参加杜卡迪工厂和博物馆的数字之旅。",
        digitalGiftCardTitle: "为您准备的礼物",
        digitalGiftCardBody: "请下载全新Panigale V4的纪念壁纸。",
        // virtual tour page
        nationalMeetingTitle: "National Meeting",
        nationalMeetingBody: "届时可以分享并讨论您所在区域的新闻、目标及战略。",
        // national meeting page
        virtualTourTitle: "杜卡迪 Virtual Tour",
        virtualTourBody: "参加博物馆和工厂的数字之旅，了解我们的过去，体验我们的当下。",
        welcomeTitle: "{NAME},您好",
        welcomeBody: "\n        <p>\u6211\u4EEC\u70ED\u70C8\u6B22\u8FCE\u60A8\u53C2\u52A02024\u5E74\u5168\u7403\u7ECF\u9500\u5546\u5927\u4F1A\uFF01\u5728\u63A5\u4E0B\u6765\u7684\u51E0\u5929\u91CC\uFF0C\u6211\u4EEC\u5C06\u5728\u6B64\u6B21\u5927\u4F1A\u4E0A\u4E0E\u60A8\u5206\u4EAB\u675C\u5361\u8FEA\u7684\u7B56\u7565\u65B9\u5411\u548C\u8FC7\u53BB\u4E00\u5E74\u6240\u83B7\u5F97\u7684\u4E30\u5BCC\u6210\u679C\uFF0C\u5E76\u5171\u540C\u63A2\u7D22\u675C\u5361\u8FEA\u7684\u6700\u65B0\u7CFB\u5217\u4EA7\u54C1</p>\n        <p>\u60A8\u8FD8\u5C06\u6709\u673A\u4F1A\u4EE5\u865A\u62DF\u7684\u65B9\u5F0F\u63A2\u7D22\u535A\u5C14\u6208\u00B7\u5E15\u5C3C\u76D6\u5C14\uFF08Borgo Panigale\uFF09\u7684\u535A\u7269\u9986\u548C\u5DE5\u5382\uFF0C\u6C89\u6D78\u5F0F\u4F53\u9A8C\u675C\u5361\u8FEA\u4E16\u754C</p>\n        <p>\u795D\u60A8\u5728\u5168\u7403\u7ECF\u9500\u5546\u5927\u4F1A\u5EA6\u8FC7\u6109\u5FEB\u7684\u65F6\u5149</p>"
    },
    ja: {
        // general
        passwordMinimumRequirements: "入力したパスワードが要件を満たしていることを確認してください。パスワードは8文字以上で、大文字、小文字、数字、記号をすべて組み合わせて設定してください。",
        settingsLabel: "設定",
        homeLabel: "ホーム",
        loginErrorMessage: "ログインエラー",
        alreadyLoggedInTitle: "すでにログインされています",
        alreadyLoggedInMessage: "先に進む前に、アクティブなセッションを閉じてください",
        alreadyLoggedInButtonLabel: "アクティブなセッションを閉じる",
        changePasswordModalTitle: "{lastname} {firstname} 様, こちらからパスワードを変更できます:",
        changeLanguageModalTitle: "言語",
        // home page
        heroConventionButtonLabel: "コンベンション",
        heroDownloadAgendaButtonLabel: "全てのプログラムをダウンロード",
        videomessageSectionTitle: "ビデオメッセージ",
        videomessageSectionCardTitle: "Beyond the ordinary roads",
        videomessageSectionCardBody: "GDC2024へようこそ！グローバルセールス＆アフターセールス事業部長フランチェスコ・ミリチアのビデオメッセージをご覧ください。",
        videomessageSectionCardCTALabel: "見る",
        agendaSectionTitle: "スケジュール",
        agendaSectionBody: "コンベンションは {convention_day} の {convention_time} に始まります。",
        agendaSectionDownloadButtonLabel: "全てのプログラムをダウンロード",
        conferenceSectionTitle: "バーチャルツアー",
        conferenceSectionConventionCardTitle: "コンベンション",
        conferenceSectionConventionCardBody: "Ducatiの戦略及び2025年ラインナッププレビュー。",
        conferenceSectionNationalMeetingCardTitle: "ナショナルミーティング",
        conferenceSectionNationalMeetingCardBody: "各地域ごとのニュース、目標、戦略。",
        conferenceSectionVirtualTourCardTitle: "バーチャルツアー",
        conferenceSectionVirtualTourCardBody: "Ducatiミュージアム＆ファクトリーのバーチャルツアーにご参加ください。",
        digitalGiftCardTitle: "デジタルギフト",
        digitalGiftCardBody: "New Panigale V4の限定壁紙をダウンロード頂けます。",
        // virtual tour page
        nationalMeetingTitle: "ナショナルミーティング",
        nationalMeetingBody: "各地域ごとにニュース、目標、戦略を共有し、話し合う機会です。",
        // national meeting page
        virtualTourTitle: "Ducatiバーチャルツアー",
        virtualTourBody: "ミュージアム＆ファクトリーのバーチャルツアーにぜひ参加し、弊社の過去と現在を体験してください。",
        welcomeTitle: "{NAME} 様",
        welcomeBody: "\n        <p>Global Dealer Conference 2024\u3078\u3088\u3046\u3053\u305D\uFF01Ducati\u306E\u6226\u7565\u3084\u672C\u5E74\u5EA6\u306E\u6210\u679C\u3092\u5171\u6709\u3057\u3001Ducati\u306E\u6700\u65B0\u30E2\u30C7\u30EB\u3092\u3054\u7D39\u4ECB\u3059\u308B\u30AB\u30F3\u30D5\u30A1\u30EC\u30F3\u30B9\u304C\u6570\u65E5\u9593\u306B\u6E21\u308A\u958B\u50AC\u3055\u308C\u307E\u3059\u3002</p>\n        <p>\u307E\u305F\u3001\u30DC\u30EB\u30B4\u30FB\u30D1\u30CB\u30AC\u30FC\u30EC\u306B\u3042\u308B\u30DF\u30E5\u30FC\u30B8\u30A2\u30E0\u3068\u30D5\u30A1\u30AF\u30C8\u30EA\u30FC\u3092\u30D0\u30FC\u30C1\u30E3\u30EB\u8A2A\u554F\u3057\u3001Ducati\u30EF\u30FC\u30EB\u30C9\u3092\u66F4\u306B\u6DF1\u304F\u4F53\u9A13\u3057\u3066\u3044\u305F\u3060\u3051\u307E\u3059\u3002</p>\n        <p>\u7D20\u6674\u3057\u3044Global Dealer Conference\u3092\u304A\u904E\u3054\u3057\u304F\u3060\u3055\u3044\uFF01</p>"
    },
    es: {
        // general
        passwordMinimumRequirements: "Asegúrate de que la contraseña introducida cumpla los requisitos: al menos 8 caracteres, una mayúscula y una minúscula, un número y un carácter especial. ",
        settingsLabel: "Configuraciones",
        homeLabel: "Inicio",
        loginErrorMessage: "Error de acceso",
        alreadyLoggedInTitle: "Ya has realizado el acceso",
        alreadyLoggedInMessage: "Cierra las sesiones activas antes de continuar",
        alreadyLoggedInButtonLabel: "Cierra las sesiones activas",
        changePasswordModalTitle: "Hola {firstname} {lastname}, aquí puedes cambiar tu contraseña:",
        changeLanguageModalTitle: "Idioma",
        // home page
        heroConventionButtonLabel: "Convención",
        heroDownloadAgendaButtonLabel: "Descarga el programa completo",
        videomessageSectionTitle: "Videomensaje",
        videomessageSectionCardTitle: "Beyond the ordinary roads",
        videomessageSectionCardBody: "¡Bienvenidos a la GDC2024! Ver el vídeo de Francesco Milicia, VP Global Sales and After Sales.",
        videomessageSectionCardCTALabel: "Mira",
        agendaSectionTitle: "Agenda",
        agendaSectionBody: "La convención comenzará a la {convention_time} del {convention_day}",
        agendaSectionDownloadButtonLabel: "Descarga el programa completo",
        conferenceSectionTitle: "Tour virtual",
        conferenceSectionConventionCardTitle: "Convención",
        conferenceSectionConventionCardBody: "La estrategia Ducati y los anticipos de la gama 2025.",
        conferenceSectionNationalMeetingCardTitle: "Reunión Nacional",
        conferenceSectionNationalMeetingCardBody: "Novedades, objetivos y estrategia de tu área.",
        conferenceSectionVirtualTourCardTitle: "Tour virtual",
        conferenceSectionVirtualTourCardBody: "Participa en la visita virtual de la fábrica y del Museo Ducati.",
        digitalGiftCardTitle: "Un regalo para ti",
        digitalGiftCardBody: "Descarga el salvapantallas conmemorativo de la nueva Panigale V4.",
        // virtual tour page
        nationalMeetingTitle: "Reunión Nacional",
        nationalMeetingBody: "La ocasión para compartir y discutir novedades, objetivos y la estrategia de tu área.",
        // national meeting page
        virtualTourTitle: "Tour Virtual Ducati",
        virtualTourBody: "Descubre nuestro pasado y vive el presente participando en la visita virtual del museo y la fábrica.",
        welcomeTitle: "Hola {NAME}",
        welcomeBody: "\n            <p>\u00A1Nos complace darte la bienvenida a la Global Dealer Conference 2024! Durante los pr\u00F3ximos d\u00EDas te espera la Convenci\u00F3n. All\u00ED, compartiremos la estrategia de la marca, los resultados del a\u00F1o pasado y descubriremos juntos las \u00FAltimas novedades de la gama Ducati.</p>\n            <p>Tambi\u00E9n tendr\u00E1s la oportunidad de explorar virtualmente el museo y la f\u00E1brica de Borgo Panigale para sumergirte a\u00FAn m\u00E1s en el mundo Ducati.</p>\n            <p>\u00A1Disfruta de la Global Dealer Conference!</p>"
    },
    pt: {
        // general
        passwordMinimumRequirements: "Certifique-se de que a senha inserida respeite os requisitos indicados: pelo menos 8 caracteres, uma letra maiúscula, uma minúscula, um número e um caráter especial. ",
        settingsLabel: "Configurações",
        homeLabel: "Página Principal",
        loginErrorMessage: "Erro de acesso",
        alreadyLoggedInTitle: "Você já está conectado",
        alreadyLoggedInMessage: "Fechar as sessões ativas antes de continuar",
        alreadyLoggedInButtonLabel: "Fechar as sessões ativas",
        changePasswordModalTitle: "Olá, {firstname} {lastname}, você pode alterar sua senha abaixo:",
        changeLanguageModalTitle: "Idioma",
        // home page
        heroConventionButtonLabel: "Convenção",
        heroDownloadAgendaButtonLabel: "Baixe a programação completa",
        videomessageSectionTitle: "Mensagem de vídeo",
        videomessageSectionCardTitle: "Beyond the ordinary roads",
        videomessageSectionCardBody: "Bem-vindo à GDC2024! Assista à mensagem de vídeo de Francesco Milicia, VP Global Vendas e Pós Vendas.",
        videomessageSectionCardCTALabel: "Assistir",
        agendaSectionTitle: "Agenda",
        agendaSectionBody: "A convenção começará às {convention_time} de {convention_day}",
        agendaSectionDownloadButtonLabel: "Baixe a programação completa",
        conferenceSectionTitle: "Tour Virtual",
        conferenceSectionConventionCardTitle: "Convenção",
        conferenceSectionConventionCardBody: "A estratégia da Ducati e as últimas novidades do portfólio 2025.",
        conferenceSectionNationalMeetingCardTitle: "Encontro Nacional",
        conferenceSectionNationalMeetingCardBody: "Novidades, objetivos e estratégia da sua área.",
        conferenceSectionVirtualTourCardTitle: "Tour Virtual",
        conferenceSectionVirtualTourCardBody: "Participe do tour virtual da fábrica e do Museu Ducati.",
        digitalGiftCardTitle: "Um presente para você",
        digitalGiftCardBody: "Baixe agora o wallpaper comemorativo da nova Panigale V4.",
        // virtual tour page
        nationalMeetingTitle: "Encontro Nacional",
        nationalMeetingBody: "A ocasião para compartilhar e discutir novidades, objetivos e estratégia da sua área.",
        // national meeting page
        virtualTourTitle: "Tour Virtual Ducati",
        virtualTourBody: "Descubra o nosso passado e viva o presente participando do tour virtual do museu e da fábrica..",
        welcomeTitle: "Olá {NAME}",
        welcomeBody: "\n            <p>temos o prazer de dar as boas-vindas \u00E0 Global Dealer Conference 2024! A Conven\u00E7\u00E3o espera por voc\u00EA nos pr\u00F3ximos dias, onde ser\u00E3o compartilhadas as escolhas estrat\u00E9gicas da Ducati, os resultados do ano passado e as \u00FAltimas novidades da linha Ducati.</p>\n            <p>Voc\u00EA tamb\u00E9m ter\u00E1 a oportunidade de explorar virtualmente o museu e a f\u00E1brica de Borgo Panigale para mergulhar ainda mais no mundo Ducati.</p>\n            <p>Aproveite a Global Dealer Conference!</p>"
    }
};
export var ducatiLanguages = {
    en: "English",
    fr: "Français",
    it: "Italiano",
    es: "Español",
    pt: "Português brasileiro",
    de: "Deutsch",
    zh: "漢語",
    ja: "日本語"
};
export var ducatiLiveChatLanguagesMapping = {
    en: "3",
    fr: "5",
    it: "4",
    es: "6",
    pt: "7",
    de: "10",
    zh: "8",
    ja: "9"
};
