var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./DucatiCardGrid.scss";
export var DucatiCardGrid = function (_a) {
    var _b;
    var title = _a.title, subtitle = _a.subtitle, items = _a.items;
    var orderedItems = items.sort(function (b, a) {
        return (a === null || a === void 0 ? void 0 : a.hide) === (b === null || b === void 0 ? void 0 : b.hide) ? 0 : (a === null || a === void 0 ? void 0 : a.hide) ? -1 : 1;
    });
    var hiddenItems = (_b = orderedItems === null || orderedItems === void 0 ? void 0 : orderedItems.filter(function (item) { return item.hide; })) === null || _b === void 0 ? void 0 : _b.length;
    return (_jsxs("div", __assign({ className: "ducati-element-cardgrid d-grid-imgtext _module-space-top" }, { children: [title && (_jsxs("div", __assign({ className: "d-title-full" }, { children: [_jsx("div", __assign({ className: "title" }, { children: title })), subtitle && _jsx("p", __assign({ className: "subtitle" }, { children: subtitle }))] }))), _jsx("div", __assign({ className: "body" }, { children: orderedItems.map(function (item) {
                    return (_jsx("div", __assign({ className: "column ".concat((item === null || item === void 0 ? void 0 : item.hide) ? "ducati-countdown-card-hidden" : "", " ").concat(hiddenItems === 2 ? "ducati-countdown-card-only-visible" : "", " ").concat(!(item === null || item === void 0 ? void 0 : item.hide) && hiddenItems === 1 ? "ducati-countdown-card-duo" : ""), "data-testid": item.testId, tabIndex: 0, style: { cursor: "pointer" }, onClick: item.onClick }, { children: _jsxs("div", __assign({ className: "d-card-imgtext" }, { children: [_jsx("div", __assign({ className: "picturewrap" }, { children: _jsx("picture", __assign({ className: "picture" }, { children: _jsx("img", { src: item.imgSrc, alt: "" }) })) })), _jsxs("div", __assign({ className: "content" }, { children: [_jsx("span", __assign({ className: "title" }, { children: item.tag })), _jsx("span", __assign({ className: "text" }, { children: item.title }))] }))] })) }), item.tag));
                }) }))] })));
};
