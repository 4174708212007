var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ChangePassword } from "../../../functional/ChangePassword/ChangePassword";
import { useAppDispatch } from "../../../../store";
import { hideModal } from "@adm-media/adam-store-plugin";
import { DUCATI_PROFILE_MODAL } from "../../../modals";
export var DucatiProfileModal = function () {
    var dispatch = useAppDispatch();
    var handleCloseModal = function () {
        dispatch(hideModal({ name: DUCATI_PROFILE_MODAL }));
    };
    return (_jsx("div", __assign({ "data-testid": "ducati-profile-modal" }, { children: _jsx(ChangePassword, { onSuccessCallback: handleCloseModal }) })));
};
export var getDucatiProfileModalProps = function () {
    return {
        modalClassName: "ducati-profile-modal"
    };
};
