import useRegistrationForm from "../../../../../hooks/useRegistrationForm";
export var enrichConfirmRegistrationFormPropsDucati = function () {
    var _a = useRegistrationForm(), contents = _a.contents, languages = _a.languages, getRegistrationFormProps = _a.getRegistrationFormProps, getTranslatedOptions = _a.getTranslatedOptions;
    var error_psw_do_not_match = contents.error_psw_do_not_match, field_required = contents.field_required, password = contents.password, password_confirmation = contents.password_confirmation, error_password_required = contents.error_password_required, specialty = contents.specialty, error_specialty_required = contents.error_specialty_required;
    var inputs = [
        // {
        //     type: "email",
        //     label: email,
        //     placeholder: email,
        //     disabled: true,
        //     populateFromIdentity: true,
        //     name: "email"
        // },
        {
            type: "password",
            label: password || "Password",
            placeholder: password || "Password",
            required: true,
            name: "password",
            rules: [
                {
                    required: true,
                    message: field_required
                }
            ]
        },
        {
            type: "password",
            label: password_confirmation,
            placeholder: password_confirmation,
            required: true,
            name: "password_confirmation",
            rules: [
                {
                    required: true,
                    message: field_required
                }
            ]
        },
        {
            label: specialty,
            name: "roles",
            placeholder: specialty,
            options: getTranslatedOptions(),
            required: true,
            type: "select-multiple",
            rules: [
                {
                    required: true,
                    message: field_required
                }
            ]
        }
    ];
    // let languageInput: any = {
    //     type: "hidden",
    //     name: "lang_id",
    //     value: languages[0].value
    // };
    // if (languages.length > 1) {
    //     languageInput = {
    //         type: "select",
    //         label: language,
    //         placeholder: language,
    //         populateFromIdentity: true,
    //         required: true,
    //         name: "lang_id",
    //         rules: [
    //             {
    //                 required: true,
    //                 message: error_language_required
    //             }
    //         ],
    //         options: languages
    //     };
    // }
    // inputs.push(languageInput);
    return getRegistrationFormProps({ inputs: inputs });
};
