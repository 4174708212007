var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DucatiDownloadICSButton } from "../../DucatiDownloadICSButton/DucatiDownloadICSButton";
import { selectAppContent, selectStyles, selectUser } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import "./DucatiCountdownBurgerMenu.scss";
import classNames from "classnames";
import { SUBPAGES } from "../../../../../pages/Watch/Watch";
import { useHashNavigate } from "../../../../../hooks/useHashNavigate";
import { useGetDucatiContents } from "../../contents/useGetDucatiContents";
import { isDucatiConventionEnabled, isDucatiNationalMeetingEnabled } from "../../../../../utils/ducati-contents-enabled";
import { useGetDucatiNationalMeetings } from "../../contents/useGetDucatiNationalMeeting";
export var DucatiCountdownBurgerMenu = function (_a) {
    var onClickLogout = _a.onClickLogout, setIsBurgerMenuOpen = _a.setIsBurgerMenuOpen;
    var hashNavigate = useHashNavigate();
    var baseClass = "ducati-countdown-burger-menu";
    var styles = useSelector(selectStyles);
    var ducatiContents = useGetDucatiContents();
    var ducatiNationalMeetings = useGetDucatiNationalMeetings();
    var identity = useSelector(selectUser).identity;
    var contents = useSelector(selectAppContent);
    var handleHashNavigation = function (hash) {
        hashNavigate(hash);
        setIsBurgerMenuOpen(false);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };
    return (_jsx("div", __assign({ className: baseClass }, { children: _jsxs("div", __assign({ className: "".concat(baseClass, "__first-level") }, { children: [(styles === null || styles === void 0 ? void 0 : styles.add_save_the_date_button) && _jsx(DucatiDownloadICSButton, { className: "".concat(baseClass, "__first-level-item") }), _jsx("button", __assign({ onClick: function () { return handleHashNavigation(SUBPAGES.home); }, className: "".concat(baseClass, "__first-level-item") }, { children: ducatiContents.homeLabel })), isDucatiConventionEnabled(identity) && (_jsx("button", __assign({ onClick: function () { return handleHashNavigation(SUBPAGES.convention); }, className: "".concat(baseClass, "__first-level-item") }, { children: ducatiContents.heroConventionButtonLabel }))), _jsx("button", __assign({ onClick: function () { return handleHashNavigation(SUBPAGES.virtualtour); }, className: "".concat(baseClass, "__first-level-item") }, { children: ducatiContents.conferenceSectionVirtualTourCardTitle })), isDucatiNationalMeetingEnabled() && ducatiNationalMeetings.length > 0 && (_jsx("button", __assign({ onClick: function () { return handleHashNavigation(SUBPAGES.nationalmeeting); }, className: "".concat(baseClass, "__first-level-item") }, { children: ducatiContents.conferenceSectionNationalMeetingCardTitle }))), _jsx("button", __assign({ onClick: onClickLogout, "data-testid": "logout-button", className: classNames("".concat(baseClass, "__first-level-item"), "".concat(baseClass, "__first-level-item--logout-button")) }, { children: contents.logout }))] })) })));
};
