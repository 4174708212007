var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { selectAppContent } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import { SanitizeHtml } from "../../../ui/SanitizeHtml/SanitizeHtml";
export var DucatiContacts = function () {
    var contents = useSelector(selectAppContent);
    return (_jsx("div", __assign({ className: "d-editor-text _module-space-top" }, { children: _jsx("div", __assign({ className: "body" }, { children: _jsx("div", __assign({ className: "content" }, { children: _jsx("p", { children: _jsx(SanitizeHtml, { stringToSanitize: contents.website_contacts, tag: "span" }) }) })) })) })));
};
export default DucatiContacts;
