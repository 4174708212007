import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useAppDispatch } from "../store";
import { displayModal, selectUser } from "@adm-media/adam-store-plugin";
import { CHANGE_PASSWORD_MODAL } from "../components/modals";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DucatiPlaceholderPage } from "./DucatiPlaceholderPage";
export var ChangePasswordPage = function () {
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var token = useSelector(selectUser).token;
    useEffect(function () {
        if (!token)
            navigate("/");
        else
            dispatch(displayModal({
                name: CHANGE_PASSWORD_MODAL
            }));
    }, [token]);
    return _jsx(DucatiPlaceholderPage, {});
};
