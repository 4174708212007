var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex } from "antd";
export var DucatiBanner = function (_a) {
    var title = _a.title, imgSrc = _a.imgSrc, bannerTitle = _a.bannerTitle, bannerSubtitle = _a.bannerSubtitle, action = _a.action;
    return (_jsxs("div", __assign({ className: "d-banner-wide -promo _module-space-top", style: { backgroundColor: "#fafafa" } }, { children: [title && (_jsx("div", __assign({ className: "d-title-full" }, { children: _jsx("div", __assign({ className: "title" }, { children: title })) }))), _jsx("div", { children: _jsxs("div", __assign({ className: "wrapper" }, { children: [_jsxs("div", __assign({ className: "text" }, { children: [_jsx("h4", __assign({ className: "title" }, { children: bannerTitle })), bannerSubtitle && _jsx("span", __assign({ className: "description" }, { children: bannerSubtitle })), action && (_jsx(Flex, __assign({ justify: "start", gap: 20 }, { children: action })))] })), _jsx("picture", __assign({ className: "image" }, { children: _jsx("img", { src: imgSrc, alt: "" }) }))] })) })] })));
};
