var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./DucatiBrowserUnsupported.scss";
import { useNotification } from "../../../../hooks/useNotification";
import { useSelector } from "react-redux";
import { selectLoadingDetect } from "@adm-media/adam-store-plugin";
import { Spin } from "../../../ui/Spin/Spin";
export var DucatiBrowserUnsupported = function () {
    var loadingDetect = useSelector(selectLoadingDetect);
    var notification = useNotification().notification;
    var isLoadingDetect = loadingDetect === "pending" || loadingDetect === "uninitialized";
    return (_jsxs(_Fragment, { children: [_jsx(Spin, { spinning: isLoadingDetect, fullPage: true }), _jsx("div", __assign({ "data-testid": "browser-unsupported-warning", className: "ducati-browser-unsupported" }, { children: _jsx("div", __assign({ className: "d-header-editoriale" }, { children: _jsx("div", __assign({ className: "body" }, { children: _jsxs("div", __assign({ className: "content" }, { children: [_jsx("div", __assign({ className: "d-utility-bar" }, { children: _jsx("ul", { className: "breadcrumbs" }) })), _jsx("h1", __assign({ className: "title" }, { children: "Browser unsupported" })), _jsx("div", __assign({ className: "subtitle" }, { children: "Please copy the link below and paste it into the address bar of one of the following browsers: Chrome, Edge, Safari, Opera." })), _jsx("p", __assign({ className: "subtitle", style: { maxWidth: "100%", overflowWrap: "break-word", wordBreak: "break-all", fontStyle: "italic" } }, { children: window.location.href })), _jsx(CopyToClipboard, __assign({ onCopy: function () { return notification.showGenericSuccess(); }, text: window.location.href }, { children: _jsx("button", __assign({ "data-testid": "unsupported-browser-url-copy-button", className: "ducati-browser-unsupported__copy-to-clipboard" }, { children: _jsx("span", { children: "Copy URL to clipboard" }) })) }))] })) })) })) }))] }));
};
