var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import AntdIcon from "@ant-design/icons";
import { DucatiAgendaIconSvg } from "./DucatiAgendaIcon";
import { DucatiContactsIconSvg } from "./DucatiContactsIcon";
import { DucatiQandAIconSvg } from "./DucatiQandAIconSvg";
import { DucatiSpeakersIconSvg } from "./DucatiSpeakersIconSvg";
import { DucatiProfileIconSvg } from "./DucatiProfileIconSvg";
export var DucatiAgendaIcon = function (props) { return _jsx(AntdIcon, __assign({}, props, { className: "ducati-icon", component: DucatiAgendaIconSvg })); };
export var DucatiContactsIcon = function (props) { return (_jsx(AntdIcon, __assign({}, props, { className: "ducati-icon", component: DucatiContactsIconSvg }))); };
export var DucatiQandAIcon = function (props) { return _jsx(AntdIcon, __assign({}, props, { className: "ducati-icon", component: DucatiQandAIconSvg })); };
export var DucatiSpeakersIcon = function (props) { return (_jsx(AntdIcon, __assign({}, props, { className: "ducati-icon", component: DucatiSpeakersIconSvg }))); };
export var DucatiProfileIcon = function (props) { return _jsx(AntdIcon, __assign({}, props, { className: "ducati-icon", component: DucatiProfileIconSvg })); };
