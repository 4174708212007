var ASSET_BASE_URL = process.env.PROTECTED_ASSETS_BASEURL || "https://assets.ducatigdc2024.com/";
export var BANNER_MESSAGE = "bannerMessage";
export var BANNER_DIGITAL_GIFT = "bannerDigitalGift";
export var VIDEO_TOUR = "videoTour";
export var VIDEO_TOUR_POSTER = "videoTourPoster";
export var VIDEO_MUSEUM = "videoMuseum";
export var VIDEO_MUSEUM_POSTER = "videoMuseumPoster";
export var VIDEO_WELCOME = "videoWelcome";
export var getProtectedAssetUrl = function (key, langId) {
    if (langId === void 0) { langId = "en"; }
    var path = "";
    switch (key) {
        case BANNER_MESSAGE:
            path = "images/a0c6816945cc83e11721cf4b81b3a6f5.png";
            break;
        case BANNER_DIGITAL_GIFT:
            path = "images/digital-gift-banner.jpeg";
            break;
        case VIDEO_TOUR_POSTER:
            path = "images/factory_tour_poster.jpeg";
            break;
        case VIDEO_MUSEUM_POSTER:
            path = "images/museum_tour_poster.jpeg";
            break;
        case VIDEO_TOUR:
            path = "videos/tour/tour_".concat(langId, ".mp4");
            break;
        case VIDEO_WELCOME:
            path = "videos/welcome/240917_gfc24_welcome_".concat(langId, ".mp4");
            break;
        case VIDEO_MUSEUM:
            if (langId === "zh") {
                langId = "en";
            }
            path = "videos/museum/museum_".concat(langId, ".m4v");
            break;
    }
    return ASSET_BASE_URL + path;
};
