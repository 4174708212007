import { selectLocaleLanguage } from "@adm-media/adam-store-plugin";
import { useEffect } from "react";
import { useSelector } from "react-redux";
// Define a mapping from language to environment variable keys
var policyUrls = {
    en: process.env.EN_IUBENDA_POLICY,
    es: process.env.ES_IUBENDA_POLICY,
    fr: process.env.FR_IUBENDA_POLICY,
    de: process.env.DE_IUBENDA_POLICY,
    it: process.env.IT_IUBENDA_POLICY,
    pt: process.env.PT_IUBENDA_POLICY,
    zh: process.env.ZH_IUBENDA_POLICY,
    ja: process.env.JA_IUBENDA_POLICY
};
var appendScript = function (src) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.charset = "UTF-8";
    script.async = true;
    document.body.appendChild(script);
};
export var useIubendaPolicy = function () {
    var currentLocale = useSelector(selectLocaleLanguage);
    var iubendaSiteId = process.env.IUBENDA_ID;
    useEffect(function () {
        if (!currentLocale || !iubendaSiteId)
            return;
        var policyWithFallback = policyUrls[currentLocale] || policyUrls["en"];
        window._iub = window._iub || [];
        // Mandatory property access
        window._iub.csConfiguration = {
            // buttonId: "iubenda-button-id",
            // askConsentAtCookiePolicyUpdate: true,
            // enableFadp: true,
            // fadpApplies: true,
            // floatingPreferencesButtonDisplay: "bottom-left",
            // perPurposeConsent: true,
            siteId: iubendaSiteId,
            // whitelabel: false,
            cookiePolicyId: policyWithFallback,
            lang: currentLocale
            // banner: {
            //     acceptButtonDisplay: true,
            //     closeButtonDisplay: false,
            //     customizeButtonDisplay: true,
            //     explicitWithdrawal: true,
            //     listPurposes: true,
            //     position: "bottom",
            //     rejectButtonDisplay: true,
            //     showTitle: false
            // }
        };
        var iubendaAutoblocking = "https://cs.iubenda.com/autoblocking/".concat(iubendaSiteId, ".js");
        var iubendaCs = "https://cdn.iubenda.com/cs/iubenda_cs.js";
        appendScript(iubendaAutoblocking);
        appendScript(iubendaCs);
        return function () {
            // document?.body.querySelector('button[class*="iubenda-tp-btn"]')?.remove();
            // document?.head.querySelector('script[src*="iubenda.com"]')?.remove();
            // document.body.querySelectorAll('script[src*="iubenda"]').forEach((script) => {
            //     document?.body?.removeChild(script);
            // });
        };
    }, [currentLocale]);
};
