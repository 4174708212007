var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { hideModal } from "@adm-media/adam-store-plugin";
import { ResetPassword } from "../../functional/ResetPassword/ResetPassword";
import { RESET_PASSWORD } from "../index";
import "./ResetPasswordModal.scss";
import { useAppDispatch } from "../../../store";
import { useNavigate } from "react-router";
import { Paths } from "../../../pages";
export var ResetPasswordModal = function (_a) {
    var emailUrl = _a.emailUrl, tokenUrl = _a.tokenUrl;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var onSuccessCallback = function () {
        navigate(Paths.home);
        dispatch(hideModal({ name: RESET_PASSWORD }));
        // dispatch(displayModal({ name: LOGIN_MODAL }));
    };
    return (_jsx("div", __assign({ "data-testid": "reset-password-modal" }, { children: _jsx(ResetPassword, { email: emailUrl, token: tokenUrl, onSuccessCallback: onSuccessCallback }) })));
};
export var getResetPasswordModalProps = function () {
    return {
        modalClassName: "reset-password-modal",
        maskClosable: false,
        closeIcon: null
    };
};
