var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import Typography from "../../../ui/Typography/Typography";
import { useGetDucatiContents } from "../contents/useGetDucatiContents";
import "./DucatiVideomessageModal.scss";
import { selectLocaleLanguage } from "@adm-media/adam-store-plugin";
import { getProtectedAssetUrl, VIDEO_WELCOME } from "../../../../utils/assets";
var Title = Typography.Title;
export var DucatiVideomessageModal = function () {
    var baseClass = "ducati-videomessage-modal";
    var currentLocale = useSelector(selectLocaleLanguage);
    var ducatiContents = useGetDucatiContents();
    return (_jsxs("div", __assign({ className: baseClass, "data-testid": "ducati-videomessage" }, { children: [_jsx(Title, __assign({ level: 3 }, { children: ducatiContents.videomessageSectionCardTitle })), _jsxs("video", __assign({ playsInline: true, controls: true, className: "".concat(baseClass, "__video") }, { children: [_jsx("source", { src: getProtectedAssetUrl(VIDEO_WELCOME, currentLocale), type: "video/mp4" }), "Your browser does not support HTML video."] }))] })));
};
export var getDucatiVideomessageModalProps = function () {
    return {
        modalClassName: "ducati-videomessage-modal-content",
        maskClosable: false
    };
};
