var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { displayModal, hideModal, selectAppContent, selectAppLanguages, selectLocaleLanguage, selectMedia, selectStyles } from "@adm-media/adam-store-plugin";
import classNames from "classnames";
import { LOGIN_MODAL, THANK_YOU_MODAL } from "../../../modals";
import { DucatiBurgerMenu } from "./DucatiBurgerMenu/DucatiBurgerMenu";
import { useActivateMenuAnimation } from "../useActivateMenuAnimation";
import { DucatiFixedLanguageSelector } from "../DucatiFixedLanguageSelector/DucatiFixedLanguageSelector";
import "./DucatiNavbar.scss";
import { DucatiNavbarTopContainer } from "../DucatiNavbarTopContainer/DucatiNavbarTopContainer";
export var MenuItems;
(function (MenuItems) {
    MenuItems["languages"] = "languages";
    MenuItems["agenda"] = "agenda";
    MenuItems["login"] = "login";
})(MenuItems || (MenuItems = {}));
export var DucatiNavbar = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var _q, _r;
    var styles = useSelector(selectStyles);
    var header_logo_desktop = useSelector(selectMedia).header_logo_desktop;
    var currentLocale = useSelector(selectLocaleLanguage);
    var languages = useSelector(selectAppLanguages);
    var collapsible = useRef(null);
    var burgerMenu = useRef(null);
    var isSpinnerActive = useActivateMenuAnimation().isSpinnerActive;
    var baseClass = "ducati-navbar";
    var dispatch = useDispatch();
    var content = useSelector(selectAppContent);
    var handleShowLoginModal = function () {
        dispatch(hideModal({ name: THANK_YOU_MODAL }));
        dispatch(displayModal({ name: LOGIN_MODAL }));
    };
    var _s = useState(false), isAnimationReady = _s[0], setIsAnimationReady = _s[1];
    useEffect(function () {
        if (!isSpinnerActive)
            setIsAnimationReady(true);
    }, [isSpinnerActive]);
    var _t = useState(false), isMenuOpen = _t[0], setIsMenuOpen = _t[1];
    var _u = useState(false), isBurgerMenuOpen = _u[0], setIsBurgerMenuOpen = _u[1];
    var _v = useState(false), isCollapsed = _v[0], setIsCollapsed = _v[1];
    var _w = useState(window.scrollY), scrollPosition = _w[0], setScrollPosition = _w[1];
    var _x = useState(null), activeMenuItem = _x[0], setActiveMenuItem = _x[1];
    useEffect(function () {
        var collapsedHandler = setTimeout(function () {
            setIsCollapsed(scrollPosition > 30);
        }, 250);
        return function () { return clearTimeout(collapsedHandler); };
    }, [scrollPosition]);
    useEffect(function () {
        var handleScroll = function () { return setScrollPosition(window.scrollY); };
        window.addEventListener("scroll", handleScroll);
        return function () {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);
    var _y = useState(window.innerWidth), windowSize = _y[0], setWindowSize = _y[1];
    useEffect(function () {
        var handleResize = function () {
            if (windowSize === window.innerWidth)
                return;
            setIsMenuOpen(false);
            setIsBurgerMenuOpen(false);
            setWindowSize(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, [windowSize]);
    useEffect(function () {
        if (isMenuOpen === false)
            setActiveMenuItem(null);
    }, [isMenuOpen]);
    var mainElementClass = classNames(baseClass, (_a = {}, _a["".concat(baseClass, "--collapsed")] = isCollapsed, _a["".concat(baseClass, "--animation-ready")] = isAnimationReady, _a));
    var containerClass = classNames("".concat(baseClass, "__content-container"), (_b = {}, _b["".concat(baseClass, "__content-container--collapsed")] = isCollapsed, _b));
    var wrapperClass = classNames("".concat(baseClass, "__content-wrapper"), (_c = {}, _c["".concat(baseClass, "__content-wrapper--hidden")] = isCollapsed, _c));
    var hiddenWrapperClass = classNames("".concat(baseClass, "__content-hidden-wrapper"), (_d = {},
        _d["".concat(baseClass, "__content-hidden-wrapper--visible")] = isCollapsed,
        _d));
    var maskClass = classNames("".concat(baseClass, "__mask"), (_e = {}, _e["".concat(baseClass, "__mask--active")] = isMenuOpen || isBurgerMenuOpen, _e));
    var collapsibleMenuClass = classNames("".concat(baseClass, "__collapsible-menu"), (_f = {}, _f["".concat(baseClass, "__collapsible-menu--open")] = isMenuOpen, _f));
    var burgerMenuContainerClass = classNames("".concat(baseClass, "__burger-menu-container"), (_g = {},
        _g["".concat(baseClass, "__burger-menu-container--open")] = isBurgerMenuOpen,
        _g));
    var burgerButtonClass = classNames("".concat(baseClass, "__burger-button"), (_h = {}, _h["".concat(baseClass, "__burger-button--open")] = isBurgerMenuOpen, _h));
    var mobileLogoClass = classNames("".concat(baseClass, "__mobile-logo"), (_j = {}, _j["".concat(baseClass, "__mobile-logo--hidden")] = isBurgerMenuOpen || isCollapsed, _j));
    var mobileMenuItemClass = classNames("".concat(baseClass, "__mobile-menu-item"), (_k = {},
        _k["".concat(baseClass, "__mobile-menu-item--visible")] = isCollapsed,
        _k["".concat(baseClass, "__mobile-menu-item--hidden")] = isBurgerMenuOpen,
        _k));
    var languageSelectorButtonWrapperClass = classNames("".concat(baseClass, "__language-selection-button-wrapper"), (_l = {},
        _l["".concat(baseClass, "__language-selection-button-wrapper--visible")] = isBurgerMenuOpen,
        _l));
    var collapsibleContent = _jsx("div", {});
    var handleAgendaAnchorClick = function () {
        if (isBurgerMenuOpen)
            setIsBurgerMenuOpen(false);
        var agendaElement = document.getElementById("agenda");
        if (agendaElement)
            agendaElement.scrollIntoView({ behavior: "smooth" });
    };
    return (_jsxs(_Fragment, { children: [(isMenuOpen || isBurgerMenuOpen) && (_jsx("style", { children: "\n                    body {\n                        overflow: hidden;\n                    }\n                " })), _jsx(DucatiNavbarTopContainer, { children: languages.length > 1 && _jsx(DucatiFixedLanguageSelector, { setFooterEventListener: true }) }), _jsx("div", { className: maskClass, onClick: function () { return setIsMenuOpen(false); } }), _jsxs("div", __assign({ className: mainElementClass, "data-testid": "block-navbar" }, { children: [_jsxs("div", __assign({ className: containerClass }, { children: [_jsxs("div", __assign({ className: "".concat(baseClass, "__mobile-content") }, { children: [_jsxs("button", __assign({ role: "menu", className: burgerButtonClass, onClick: function () { return setIsBurgerMenuOpen(function (ps) { return !ps; }); } }, { children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] })), _jsx("div", __assign({ className: mobileLogoClass }, { children: _jsx("img", { src: header_logo_desktop === null || header_logo_desktop === void 0 ? void 0 : header_logo_desktop.url, alt: "DUCATI", itemProp: "logo" }) })), _jsx("a", __assign({ className: mobileMenuItemClass, onClick: handleShowLoginModal }, { children: content.login })), languages.length > 1 && (_jsx("div", __assign({ className: languageSelectorButtonWrapperClass }, { children: _jsx(DucatiFixedLanguageSelector, { setFooterEventListener: false }) })))] })), _jsxs("div", __assign({ className: "".concat(baseClass, "__desktop-content") }, { children: [_jsxs("div", __assign({ className: wrapperClass }, { children: [_jsx("div", __assign({ className: "".concat(baseClass, "__left-items") }, { children: _jsx("a", __assign({ className: classNames("".concat(baseClass, "__menu-item"), (_m = {},
                                                        _m["".concat(baseClass, "__menu-item--active")] = activeMenuItem === MenuItems.agenda,
                                                        _m["".concat(baseClass, "__menu-item--inactive")] = activeMenuItem !== null && activeMenuItem !== MenuItems.agenda,
                                                        _m)), onClick: handleAgendaAnchorClick }, { children: content.agenda_title })) })), _jsx("div", __assign({ className: "".concat(baseClass, "__logo") }, { children: _jsx("img", { src: header_logo_desktop === null || header_logo_desktop === void 0 ? void 0 : header_logo_desktop.url, alt: "DUCATI", itemProp: "logo" }) })), _jsx("div", __assign({ className: "".concat(baseClass, "__right-items") }, { children: _jsx("a", __assign({ className: classNames("".concat(baseClass, "__menu-item"), (_o = {},
                                                        _o["".concat(baseClass, "__menu-item--active")] = activeMenuItem === MenuItems.login,
                                                        _o["".concat(baseClass, "__menu-item--inactive")] = activeMenuItem !== null && activeMenuItem !== MenuItems.login,
                                                        _o)), onClick: handleShowLoginModal, "data-testid": "navbar-login-button" }, { children: content.login })) }))] })), _jsx("div", __assign({ className: hiddenWrapperClass }, { children: _jsx("div", __assign({ className: "".concat(baseClass, "__left-items") }, { children: _jsx("a", __assign({ className: classNames("".concat(baseClass, "__menu-item"), (_p = {},
                                                    _p["".concat(baseClass, "__menu-item--active")] = activeMenuItem === MenuItems.login,
                                                    _p["".concat(baseClass, "__menu-item--inactive")] = activeMenuItem !== null && activeMenuItem !== MenuItems.login,
                                                    _p)), onClick: handleShowLoginModal }, { children: content.login })) })) }))] }))] })), _jsx("div", __assign({ ref: collapsible, className: collapsibleMenuClass, style: {
                            height: isMenuOpen ? (_q = collapsible.current) === null || _q === void 0 ? void 0 : _q.scrollHeight : 0
                        } }, { children: collapsibleContent })), _jsxs("div", __assign({ ref: burgerMenu, className: burgerMenuContainerClass, style: {
                            height: isBurgerMenuOpen ? (_r = burgerMenu.current) === null || _r === void 0 ? void 0 : _r.scrollHeight : 0
                        } }, { children: [_jsx(DucatiBurgerMenu, { onAgendaAnchorClick: handleAgendaAnchorClick, onClickLogin: handleShowLoginModal }), languages.length > 1 && (_jsx("div", __assign({ className: "".concat(baseClass, "__burger-menu-language-selection-wrapper") }, { children: _jsx(DucatiFixedLanguageSelector, { setFooterEventListener: false }) })))] }))] }))] }));
};
