var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Button from "../../../ui/Buttons/Button";
import Typography from "../../../ui/Typography/Typography";
import { useSelector } from "react-redux";
import { selectAppContent, selectCloseActiveSession } from "@adm-media/adam-store-plugin";
import "./CloseActiveSession.scss";
import { useGetDucatiContents } from "../../../project-specific/ducati/contents/useGetDucatiContents";
var Paragraph = Typography.Paragraph;
export var CloseActiveSession = function (_a) {
    var handleCloseActiveSession = _a.handleCloseActiveSession;
    var loadingCloseActiveSession = useSelector(selectCloseActiveSession).loading;
    var contents = useSelector(selectAppContent);
    var ducatiContents = useGetDucatiContents();
    return (_jsxs(_Fragment, { children: [_jsx(Paragraph, __assign({ className: "have-active-session__paragraph" }, { children: ducatiContents.alreadyLoggedInMessage })), _jsx(Button, __assign({ "data-testid": "close-active-session-button", type: "primary", loading: loadingCloseActiveSession === "pending", onClick: handleCloseActiveSession }, { children: ducatiContents.alreadyLoggedInButtonLabel }))] }));
};
