import { selectAreEpisodesLoading, selectIsLoadingLanguage, selectLoadingDetect, selectLogout, selectMe, selectRegisteredSelectEpisode, selectUnsubscribeSubscriber } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
export var useActivateMenuAnimation = function () {
    var loadingDetect = useSelector(selectLoadingDetect);
    var loadingEpisodes = useSelector(selectAreEpisodesLoading);
    var loadingLogout = useSelector(selectLogout).loading;
    var loadingMe = useSelector(selectMe).loading;
    var loadingUnsubscribeSubscriber = useSelector(selectUnsubscribeSubscriber).loading;
    var isLoadingRegisterSelectedEpisode = useSelector(selectRegisteredSelectEpisode).isLoading;
    var isLoadingLanguage = useSelector(selectIsLoadingLanguage);
    var isLoadingDetect = loadingDetect === "pending" || loadingDetect === "uninitialized";
    var isLoadingEpisodes = loadingEpisodes === "pending" || loadingEpisodes === "uninitialized";
    var isLoadingLogout = loadingLogout === "pending";
    var isLoadingMe = loadingMe === "pending";
    var isLoadingUnsubscribeSubscriber = loadingUnsubscribeSubscriber === "pending";
    var isSpinnerActive = isLoadingRegisterSelectedEpisode ||
        isLoadingLanguage ||
        isLoadingDetect ||
        isLoadingEpisodes ||
        isLoadingLogout ||
        isLoadingMe ||
        isLoadingUnsubscribeSubscriber;
    return { isSpinnerActive: isSpinnerActive };
};
