import { SUBPAGES } from "../pages/Watch/Watch";
var VODCurrentTime = function (episodeId) { return "vod-current-time-".concat(process.env.NODE_ENV, "-").concat(episodeId); };
export var getVodCurrentTime = function (episodeId) { return localStorage.getItem(VODCurrentTime(episodeId)); };
export var setVodCurrentTime = function (episodeId, value) { return localStorage.setItem(VODCurrentTime(episodeId), value); };
export var removeVodCurrentTime = function (episodeId) { return localStorage.removeItem(VODCurrentTime(episodeId)); };
export var getLocalStorageOffsetFieldName = function (postFix) {
    return "vod-offset-".concat(process.env.NODE_ENV, "-").concat(postFix);
};
// Ducati specific
export var getSubpageHashNavigation = function () {
    var hashFromLocalStorage = localStorage.getItem("ducati-subpage-hash-navigation");
    if (!hashFromLocalStorage)
        return "#home";
    var cleanedHash = hashFromLocalStorage.replace("#", "");
    if (Object.values(SUBPAGES).includes(cleanedHash))
        return hashFromLocalStorage;
    else
        return "#home";
};
