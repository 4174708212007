var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DucatiFooter from "../../components/project-specific/ducati/DucatiFooter/DucatiFooter";
import { LoginForm } from "../../components/blocks/LoginForm/LoginForm";
import { useSelector } from "react-redux";
import { selectAppLanguages, selectMedia } from "@adm-media/adam-store-plugin";
import ducatiLoginLogo from "../../components/project-specific/ducati/assets/ducati-login-logo.svg";
import "./DucatiHome.scss";
import { DucatiFixedLanguageSelector } from "../../components/project-specific/ducati/DucatiFixedLanguageSelector/DucatiFixedLanguageSelector";
var DucatiHomePage = function () {
    var _a;
    var media = useSelector(selectMedia);
    var languages = useSelector(selectAppLanguages);
    var baseClass = "ducati-homepage";
    return (_jsxs("div", __assign({ "data-testid": "ducati-homepage", className: baseClass }, { children: [_jsx("div", __assign({ "aria-hidden": "true" }, { children: (languages === null || languages === void 0 ? void 0 : languages.length) > 1 && _jsx(DucatiFixedLanguageSelector, { setFooterEventListener: true }) })), _jsxs("div", __assign({ className: "".concat(baseClass, "__content-container") }, { children: [_jsxs("div", __assign({ className: "".concat(baseClass, "__form-container") }, { children: [_jsx("div", __assign({ className: "".concat(baseClass, "__logo ").concat(baseClass, "__logo--desktop") }, { children: _jsx("img", { src: ducatiLoginLogo, alt: "Logo" }) })), _jsx("div", __assign({ className: "".concat(baseClass, "__logo ").concat(baseClass, "__logo--mobile") }, { children: _jsx("img", { src: ducatiLoginLogo, alt: "Logo" }) })), _jsx(LoginForm, {})] })), _jsx("div", __assign({ className: "".concat(baseClass, "__image-container") }, { children: _jsx("img", { className: "".concat(baseClass, "__image"), srcSet: (_a = media.right_col_background) === null || _a === void 0 ? void 0 : _a.url, alt: "", "data-object-fit": "cover" }) }))] })), _jsx(DucatiFooter, {})] })));
};
export { DucatiHomePage };
