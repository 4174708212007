var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLocalStorageOffsetFieldName } from "../../../utils/local-storage";
import Player from "./Player";
import { fetchSourceThunk, selectEventId, selectCurrentEpisode, selectIsPlayerPaused, selectPlayerSource, selectUser, setIsPlayerPaused, displayModal, resetKeepCurrentTime, subscribeChannelPresenceThunk, selectActiveMultipleSource, selectFormattedPlayerSource, selectDocumentVisibilityStatus, selectAppContent, selectSelectedAudioLanguage, selectSelectedCCLanguage, setSelectedAudioLanguage, setSelectedCCLanguage, logoutUserThunk, selectLocaleLanguage, selectIsSourceReady, selectNeedsWebhookUpdateEvent, setNeedsWebhookUpdateEvent, selectStyles } from "@adm-media/adam-store-plugin";
import { LIVE, PAUSE, PLAY, UNKNOWN, UPDATE, VOD } from "../../../types/player";
import { usePlayerService } from "../../../hooks/usePlayerService";
import { exitFullScreenMode, changeCCLanguageOfPlayer, changeAudioLanguageOfPlayer, getTextTrackLanguage, startVODFrom, transformIsoLngTwoDigits, transformIsoLngThreeDigits } from "../../../utils/player";
import { getDiffTime } from "../../../utils/date";
import UnMuteButton from "../../ui/Buttons/Unmute/UnMuteButton";
import classNames from "classnames";
import FakePlayer from "../../ui/FakePlayer/FakePlayer";
import { QUIZ_MODAL } from "../../modals";
import { useAppDispatch } from "../../../store";
import { useShowQuizButton } from "../../../hooks/useShowQuizButton";
import "./Wrapper.scss";
import { subscribeToPusherIdChannel } from "../../../pusher/subscribe-to-channel-id";
import { Watermark } from "antd";
import { useWatermarkContent } from "../../../hooks/useWatermarkContent";
import { applyOpacityToHexColor } from "../../../utils/applyOpacityToHexColor";
import { useSetDrawerChapters } from "../../../hooks/useSetDrawerChapters";
import { useSetChapterTimeSelection } from "../../../hooks/useSetChapterTimeSelection";
var VOD_QUIZ_MODAL_TIME_OFFSET = 10;
export var Wrapper = function (_a) {
    var _b, _c;
    var pusherSingleton = _a.pusherSingleton;
    var dispatch = useAppDispatch();
    var _d = usePlayerService(), logPlayerEvent = _d.logPlayerEvent, logPlayerInsight = _d.logPlayerInsight;
    var _e = useShowQuizButton(), isQuizOpen = _e.isQuizOpen, isQuizAlreadyAnswered = _e.isQuizAlreadyAnswered;
    var documentVisibilityStatus = useSelector(selectDocumentVisibilityStatus);
    var eventId = useSelector(selectEventId);
    var styles = useSelector(selectStyles);
    var locale = useSelector(selectLocaleLanguage);
    var contents = useSelector(selectAppContent);
    var _f = useSelector(selectUser), token = _f.token, identity = _f.identity;
    var episodeId = useSelector(selectCurrentEpisode).id;
    var isSourceReady = useSelector(selectIsSourceReady);
    var formattedPlayerSource = useSelector(selectFormattedPlayerSource);
    var activeMultipleSource = useSelector(selectActiveMultipleSource);
    var playerSource = useSelector(selectPlayerSource);
    var _g = useSelector(selectPlayerSource), channel = _g.channel, episode_live_started_at = _g.episode_live_started_at, keepVODCurrentTime = _g.keep_current_time;
    var selectedAudioLanguage = useSelector(selectSelectedAudioLanguage);
    var selectedCCLanguage = useSelector(selectSelectedCCLanguage);
    var needsWebhookUpdateEvent = useSelector(selectNeedsWebhookUpdateEvent);
    var isPlayerPaused = useSelector(selectIsPlayerPaused);
    var _h = useState(true), isPlayerMuted = _h[0], setIsPlayerMuted = _h[1];
    var _j = useState(false), isInitialLanguageSet = _j[0], setIsInitialLanguageSet = _j[1];
    var _k = useState(null), liveStartedAt = _k[0], setLiveStartedAt = _k[1];
    var _l = useState(), playerReadyState = _l[0], setPlayerReadyState = _l[1];
    var _m = useState(undefined), playerRef = _m[0], setPlayerRef = _m[1];
    var _o = useState(false), isQuizBlocked = _o[0], setIsQuizBlocked = _o[1];
    var _p = useState(false), isFirstInsightFired = _p[0], setIsFirstInsightFired = _p[1];
    var _q = useState(null), playerInsightPausedTime = _q[0], setPlayerInsightPausedTime = _q[1];
    var playerBoxClassNames = classNames("video-wrapper", { "playerbox-overlay": !!!(formattedPlayerSource && isSourceReady) });
    var _r = useState(undefined), pusherIdChannel = _r[0], setPusherIdChannel = _r[1];
    useEffect(function () {
        if (episodeId)
            dispatch(fetchSourceThunk(episodeId))
                .unwrap()
                .then(function (data) {
                try {
                    var id = data.id;
                    if (token && eventId && !pusherIdChannel) {
                        setPusherIdChannel(subscribeToPusherIdChannel({
                            pusherSingleton: pusherSingleton,
                            config: { token: token, appId: eventId, channelId: id }
                        }));
                    }
                }
                catch (_a) { }
            })
                .catch(function () { return dispatch(logoutUserThunk()); });
    }, [episodeId, locale]);
    useEffect(function () {
        return function () {
            try {
                if (pusherIdChannel)
                    pusherIdChannel.unsubscribe();
            }
            catch (_a) { }
        };
    }, [pusherIdChannel]);
    useEffect(function () {
        if (formattedPlayerSource && isSourceReady) {
            setIsInitialLanguageSet(false);
            setIsFirstInsightFired(false);
        }
    }, [formattedPlayerSource, isSourceReady]);
    useEffect(function () {
        if (eventId && playerSource.id && episodeId)
            dispatch(subscribeChannelPresenceThunk(playerSource.id));
    }, [eventId, playerSource.id, episodeId]);
    useEffect(function () {
        if (episode_live_started_at)
            setLiveStartedAt(episode_live_started_at);
    }, [episode_live_started_at]);
    /** VOD Time handling */
    useEffect(function () {
        if (playerSource.status === VOD && playerRef && formattedPlayerSource && startVODFrom(episodeId) !== null) {
            if (keepVODCurrentTime !== false) {
                if (documentVisibilityStatus === "hidden")
                    setPlayerInsightPausedTime(startVODFrom(episodeId));
                playerRef.currentTime = startVODFrom(episodeId);
            }
            else if (keepVODCurrentTime === false) {
                localStorage.setItem(getLocalStorageOffsetFieldName(episodeId), "0");
                dispatch(resetKeepCurrentTime());
            }
        }
    }, [playerSource.status, playerRef, formattedPlayerSource, episodeId]);
    /** FAKE LIVE Time handling */
    useEffect(function () {
        if ((playerSource === null || playerSource === void 0 ? void 0 : playerSource.status) === LIVE && formattedPlayerSource && isSourceReady && playerRef && (channel === null || channel === void 0 ? void 0 : channel.is_fake_live) && liveStartedAt) {
            if (documentVisibilityStatus === "visible") {
                playerRef.currentTime = getDiffTime(liveStartedAt);
                playerRef === null || playerRef === void 0 ? void 0 : playerRef.play();
            }
        }
    }, [formattedPlayerSource, isSourceReady, playerRef, liveStartedAt, playerSource.status, channel === null || channel === void 0 ? void 0 : channel.is_fake_live, documentVisibilityStatus]);
    useEffect(function () {
        var interval = setInterval(function () {
            try {
                if ((playerSource === null || playerSource === void 0 ? void 0 : playerSource.status) === VOD && playerRef && playerReadyState && episodeId && typeof (playerRef === null || playerRef === void 0 ? void 0 : playerRef.currentTime) === "number") {
                    localStorage.setItem(getLocalStorageOffsetFieldName(episodeId), Math.floor(playerRef === null || playerRef === void 0 ? void 0 : playerRef.currentTime).toString());
                }
                if (!isQuizOpen || isQuizAlreadyAnswered || (playerSource === null || playerSource === void 0 ? void 0 : playerSource.status) !== VOD)
                    return;
                if (typeof (playerRef === null || playerRef === void 0 ? void 0 : playerRef.currentTime) !== "number" || typeof (playerRef === null || playerRef === void 0 ? void 0 : playerRef.duration) !== "number")
                    return;
                if ((playerRef === null || playerRef === void 0 ? void 0 : playerRef.duration) - (playerRef === null || playerRef === void 0 ? void 0 : playerRef.currentTime) < VOD_QUIZ_MODAL_TIME_OFFSET && !isQuizBlocked) {
                    setIsQuizBlocked(true);
                    exitFullScreenMode();
                    dispatch(displayModal({ name: QUIZ_MODAL }));
                }
            }
            catch (_a) {
                console.warn("Error in handling VOD status");
            }
        }, 1000);
        return function () { return clearInterval(interval); };
    }, [playerRef, episodeId, playerSource === null || playerSource === void 0 ? void 0 : playerSource.status, isQuizOpen, isQuizAlreadyAnswered, isQuizBlocked, playerReadyState]);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var areSourcesEqual = ((_b = (_a = formattedPlayerSource === null || formattedPlayerSource === void 0 ? void 0 : formattedPlayerSource.sources) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.src) !== undefined &&
            ((_e = (_d = (_c = playerRef === null || playerRef === void 0 ? void 0 : playerRef.source) === null || _c === void 0 ? void 0 : _c.sources) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.src) === ((_g = (_f = formattedPlayerSource === null || formattedPlayerSource === void 0 ? void 0 : formattedPlayerSource.sources) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.src);
        if ((playerSource.status === VOD || playerSource.status === LIVE) && areSourcesEqual && isSourceReady) {
            if (playerRef && !isInitialLanguageSet && playerReadyState === 4 && playerRef.readyState === 4) {
                var audioLanguageToSet_1 = selectedAudioLanguage || transformIsoLngThreeDigits(identity.lang_id);
                var doesPlayerHaveAudioLanguage = !!playerRef.audioTracks.find(function (track) { return track.language === audioLanguageToSet_1; });
                if (!doesPlayerHaveAudioLanguage) {
                    audioLanguageToSet_1 = (_h = playerRef.audioTracks[0]) === null || _h === void 0 ? void 0 : _h.language;
                    dispatch(setSelectedAudioLanguage(audioLanguageToSet_1));
                }
                else {
                    changeAudioLanguageOfPlayer(playerRef, audioLanguageToSet_1);
                    dispatch(setSelectedAudioLanguage(audioLanguageToSet_1));
                }
                var ccLanguageToSet_1 = selectedCCLanguage || transformIsoLngThreeDigits(identity.lang_id);
                var doesPlayerHaveCCLanguage = !!playerRef.textTracks.filter(function (track) {
                    return (track.kind === "subtitles" &&
                        (track.language === ccLanguageToSet_1 || track.language === transformIsoLngTwoDigits(ccLanguageToSet_1)));
                });
                changeCCLanguageOfPlayer(playerRef, ccLanguageToSet_1);
                setSelectedCCLanguage(doesPlayerHaveCCLanguage ? ccLanguageToSet_1 : null);
                setIsInitialLanguageSet(true);
            }
        }
    }, [playerRef, isInitialLanguageSet, playerReadyState, identity.lang_id, playerSource.status, formattedPlayerSource, isSourceReady]);
    useEffect(function () {
        if (selectedCCLanguage && playerRef && isFirstInsightFired)
            updateLogPlayer();
    }, [selectedCCLanguage]);
    useEffect(function () {
        if (selectedAudioLanguage && playerRef && isFirstInsightFired)
            updateLogPlayer();
    }, [selectedAudioLanguage]);
    var playerInsightData = {
        channel_id: playerSource.id,
        audio_lang_id: selectedAudioLanguage ? transformIsoLngTwoDigits(selectedAudioLanguage) : identity.lang_id,
        cc_lang_id: transformIsoLngTwoDigits(getTextTrackLanguage(playerRef)),
        status: playerSource.status,
        channel_source_id: activeMultipleSource === null || activeMultipleSource === void 0 ? void 0 : activeMultipleSource.id
    };
    useEffect(function () {
        if (isFirstInsightFired || !playerSource.id || ((playerSource.status === VOD || playerSource.status === LIVE) && !isInitialLanguageSet))
            return;
        logPlayerInsight(playerInsightData)
            .unwrap()
            .then(function () { return setIsFirstInsightFired(true); });
    }, [playerSource.id, playerSource.status, activeMultipleSource, isInitialLanguageSet, isFirstInsightFired]);
    useEffect(function () {
        /** This API call is made only on LIVE and VOD. The logic for that is in the store package */
        if (isFirstInsightFired) {
            var pauseTime = playerInsightPausedTime;
            if (!needsWebhookUpdateEvent) {
                logPlayerEvent(isPlayerPaused ? PAUSE : PLAY, { playerSource: playerSource, player: playerRef, episodeId: episodeId, pauseTime: pauseTime });
            }
            else {
                // reset need for update event after a change source
                dispatch(setNeedsWebhookUpdateEvent(false));
                logPlayerEvent(UPDATE, { playerSource: playerSource, player: playerRef, episodeId: episodeId, pauseTime: pauseTime });
            }
            setPlayerInsightPausedTime(null);
        }
    }, [isPlayerPaused, isFirstInsightFired]);
    var updateLogPlayer = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, logPlayerInsight(playerInsightData).unwrap()];
                case 1:
                    _a.sent();
                    logPlayerEvent(UPDATE, { playerSource: playerSource, player: playerRef, episodeId: episodeId });
                    return [2 /*return*/];
            }
        });
    }); };
    var _s = useWatermarkContent({
        watermarkString: "".concat(identity.email, "-").concat((identity.id || 0) + 8888),
        playerSource: playerSource,
        playerReadyState: playerReadyState,
        playerRef: playerRef
    }), setCurrentPlayerTime = _s.setCurrentPlayerTime, watermarkContent = _s.watermarkContent, hasWatermarkAtSomePoint = _s.hasWatermarkAtSomePoint;
    useSetDrawerChapters({ playerRef: playerRef, playerReadyState: playerReadyState });
    useSetChapterTimeSelection({ playerRef: playerRef });
    var watermarkGapX = styles.watermark_gap_x ? parseInt(styles.watermark_gap_x) : 5;
    var watermarkGapY = styles.watermark_gap_y ? parseInt(styles.watermark_gap_y) : 5;
    var watermarkFontSize = styles.watermark_font_size ? parseInt(styles.watermark_font_size) : 11;
    return (_jsx(Watermark, __assign({ gap: [watermarkGapX, watermarkGapY], rotate: 0, content: watermarkContent, font: {
            color: styles.watermark_text_color ? applyOpacityToHexColor(styles.watermark_text_color, styles.watermark_text_opacity) : "#ffffff4a",
            fontSize: Number.isNaN(watermarkFontSize) ? 11 : watermarkFontSize
        }, zIndex: 0 }, { children: _jsxs("div", __assign({ className: playerBoxClassNames, "data-testid": "wrapper" }, { children: [isPlayerMuted && isSourceReady && formattedPlayerSource && (_jsx(UnMuteButton, { hideButton: !isPlayerMuted, onClick: function () { return setIsPlayerMuted(false); } })), isSourceReady && formattedPlayerSource && playerSource.status && playerSource.status !== UNKNOWN ? (_jsx(Player, { autoplay: true, isFakeLive: !!(((_b = playerSource.channel) === null || _b === void 0 ? void 0 : _b.is_fake_live) && playerSource.status === LIVE), loop: playerSource.status !== VOD && playerSource.status !== LIVE, onPause: function () { return dispatch(setIsPlayerPaused(true)); }, onPlay: function () { return dispatch(setIsPlayerPaused(false)); }, source: formattedPlayerSource, onAudioTrackChange: isFirstInsightFired
                        ? function (_, trEv) {
                            return (trEv === null || trEv === void 0 ? void 0 : trEv.track.enabled) &&
                                selectedAudioLanguage !== (trEv === null || trEv === void 0 ? void 0 : trEv.track.enabled) &&
                                dispatch(setSelectedAudioLanguage(trEv.track.language));
                        }
                        : undefined, onTextTrackChange: function (player) {
                        return selectedCCLanguage !== getTextTrackLanguage(player) && dispatch(setSelectedCCLanguage(getTextTrackLanguage(player)));
                    }, onPlayerReady: setPlayerRef, onReadyStateChange: function (_, readystate) {
                        setPlayerReadyState(readystate);
                    }, onTimeUpdate: function (player) {
                        if (typeof (player === null || player === void 0 ? void 0 : player.currentTime) === "number")
                            setCurrentPlayerTime(player.currentTime);
                    }, disableFullScreen: hasWatermarkAtSomePoint, widescreen: true, forceLiveUi: !!((_c = playerSource.channel) === null || _c === void 0 ? void 0 : _c.is_fake_live) && playerSource.status === LIVE, muted: isPlayerMuted, hideSpeed: true, onVolumeChange: isPlayerMuted ? function (player) { return setIsPlayerMuted(!!(player === null || player === void 0 ? void 0 : player.muted)); } : undefined })) : playerSource.status === UNKNOWN ? (_jsx(FakePlayer, { children: (contents === null || contents === void 0 ? void 0 : contents.not_eligible) || "" })) : (_jsx(FakePlayer, {}))] })) })));
};
