var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { resetNewQuestion, selectAppContent, selectCurrentEpisode, selectIsEnabledQA, selectMeCurrentStreamInfo, sendQuestionThunk, selectNewQuestion } from "@adm-media/adam-store-plugin";
import QAForm from "../QAForm/QAForm";
import "./QASection.scss";
import { useAppDispatch } from "../../../store";
import { useNotification } from "../../../hooks/useNotification";
var QASection = function (_a) {
    var props = __rest(_a, []);
    var notification = useNotification().notification;
    var dispatch = useAppDispatch();
    var isEnabledQA = useSelector(selectIsEnabledQA);
    var episode_id = useSelector(selectCurrentEpisode).id;
    var _b = useSelector(selectMeCurrentStreamInfo), streamId = _b.streamId, streamName = _b.streamName;
    var loadingNewQuestion = useSelector(selectNewQuestion).loading;
    var contents = useSelector(selectAppContent);
    var handleSubmit = function (values) {
        dispatch(sendQuestionThunk({
            episode_id: episode_id,
            original_content: values.question,
            stream_id: streamId,
            stream_name: streamName
        }))
            .unwrap()
            .then(function () {
            setTimeout(function () {
                try {
                    dispatch(resetNewQuestion());
                }
                catch (_a) { }
            }, 3000);
        })
            .catch(function () {
            notification.showGenericError();
            resetNewQuestion();
        });
    };
    var handleReset = function () { return dispatch(resetNewQuestion()); };
    useEffect(function () {
        return function () {
            handleReset();
        };
    }, []);
    return (_jsxs("div", __assign({ className: "qa", "data-test": "qa-form" }, props, { children: [!(loadingNewQuestion === "succeeded") && (_jsx(QAForm, { loading: loadingNewQuestion === "pending", submitButtonText: (contents === null || contents === void 0 ? void 0 : contents.submit_question) || "Submit", placeholder: (contents === null || contents === void 0 ? void 0 : contents.qna_form_textarea_placeholder) || undefined, onSubmit: handleSubmit, disabled: !isEnabledQA })), loadingNewQuestion === "succeeded" && (_jsx("div", __assign({ className: "qa__success" }, { children: (contents === null || contents === void 0 ? void 0 : contents.question_submitted) || "Thank you for submitting the question" })))] })));
};
export default QASection;
