export var isDucatiConventionEnabled = function (identity) {
    var _a, _b;
    // @ts-ignore
    var isEnabledConvention = (_b = (_a = identity === null || identity === void 0 ? void 0 : identity.subscription) === null || _a === void 0 ? void 0 : _a.subscription_data) === null || _b === void 0 ? void 0 : _b.preview;
    try {
        if (process.env.DUCATI_CONTENT_CONVENTION_ENABLED === "1" || !!isEnabledConvention)
            return true;
        else
            return false;
    }
    catch (_c) {
        return false;
    }
};
export var isDucatiNationalMeetingEnabled = function () {
    try {
        if (process.env.DUCATI_CONTENT_NATIONALMEETING_ENABLED === "1")
            return true;
        else
            return false;
    }
    catch (_a) {
        return false;
    }
};
export var isDucatiVideomessageEnabled = function () {
    try {
        if (process.env.DUCATI_CONTENT_VIDEOMESSAGE_ENABLED === "1")
            return true;
        else
            return false;
    }
    catch (_a) {
        return false;
    }
};
export var isDucatiDigitalGiftEnabled = function () {
    try {
        if (process.env.DUCATI_CONTENT_DIGITALGIFT_ENABLED === "1")
            return true;
        else
            return false;
    }
    catch (_a) {
        return false;
    }
};
