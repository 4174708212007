var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Badge, Button, Dropdown } from "antd";
import { BellFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectNotifications, selectReadNotificationsIds } from "@adm-media/adam-store-plugin";
import "./DucatiNotifications.scss";
import { DucatiNotificationItem } from "./DucatiNotificationItem/DucatiNotificationItem";
export var DucatiNotifications = function (_a) {
    var _b;
    var keepDropdownClosed = _a.keepDropdownClosed;
    var notifications = useSelector(selectNotifications);
    var baseClass = "ducati-notifications-dropdown";
    var readNotificationsIds = useSelector(selectReadNotificationsIds);
    var agumentedNotifications = notifications === null || notifications === void 0 ? void 0 : notifications.map(function (notification) {
        return {
            label: (_jsx(DucatiNotificationItem, { url: notification.url, id: notification.id, title: notification.notification_title, description: notification.notification_body })),
            key: notification.id
        };
    });
    var unreadNotificationsCount = (_b = notifications === null || notifications === void 0 ? void 0 : notifications.filter(function (notification) { return !(readNotificationsIds === null || readNotificationsIds === void 0 ? void 0 : readNotificationsIds.includes(notification === null || notification === void 0 ? void 0 : notification.id)); })) === null || _b === void 0 ? void 0 : _b.length;
    return (_jsx(Dropdown, __assign({ open: keepDropdownClosed === true ? false : (notifications === null || notifications === void 0 ? void 0 : notifications.length) === 0 ? false : undefined, overlayClassName: baseClass, arrow: true, menu: { items: agumentedNotifications }, placement: "bottomRight", trigger: ["click"], align: { offset: [0, 32] } }, { children: _jsx(Badge, __assign({ showZero: false, offset: [-6, 0], count: unreadNotificationsCount }, { children: _jsx(Button, { className: "ducati-notifications-button", disabled: (notifications === null || notifications === void 0 ? void 0 : notifications.length) === 0, onClick: function (e) { return e.preventDefault(); }, icon: _jsx(BellFilled, { className: "ducati-notifications-button__icon" }) }) })) })));
};
