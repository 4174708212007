var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useSelector } from "react-redux";
import { selectRegistrationFormId } from "@adm-media/adam-store-plugin";
import { enrichRegistrationFormPropsMsdSpecial } from "./registration-form/props-enrichers-categories/msdSpecial";
import { enrichRegistrationFormPropsEurogin } from "./registration-form/props-enrichers-categories/eurogin";
import { enrichRegistrationFormPropsMsd } from "./registration-form/props-enrichers-categories/msd";
import { enrichRegistrationFormPropsMsdGlobalHpv } from "./registration-form/props-enrichers-categories/msdGlobalHpv";
import { enrichRegistrationFormPropsMsdId } from "./registration-form/props-enrichers-categories/msdIdForum";
import { enrichRegistrationFormPropsDucati } from "./registration-form/props-enrichers-categories/ducati";
import { enrichRegistrationFormPropsEdizioniDm } from "./registration-form/props-enrichers-categories/edizioniDM";
import { enrichRegistrationFormPropsMsdApidForumCovid19 } from "./registration-form/props-enrichers-categories/msdApidForumCovid19";
import { enrichRegistrationFormPropsMsdPedVaccines } from "./registration-form/props-enrichers-categories/pedVaccines";
import { enrichRegistrationFormPropsMsdCtio } from "./registration-form/props-enrichers-categories/msdCtio";
import { enrichRegistrationFormPropsGynecCancer } from "./registration-form/props-enrichers-categories/gynecCancer";
import { enrichRegistrationFormPropsOrganonVirtualSass } from "./registration-form/props-enrichers-categories/organonVirtualSass";
import { enrichRegistrationFormPropsMsdGlobalCmv } from "./registration-form/props-enrichers-categories/msdGlobablCmv";
import { enrichRegistrationFormPropsMsdHncrResearchForum } from "./registration-form/props-enrichers-categories/msdHncrResearch";
import { enrichRegistrationFormPropsMsdType2 } from "./registration-form/props-enrichers-categories/msdType2";
import { enrichRegistrationFormAnimalHealth } from "./registration-form/props-enrichers-categories/animalHealth";
import { enrichRegistrationFormStandardPasswordCompany } from "./registration-form/props-enrichers-categories/standardPasswordCompany";
import { enrichConfirmRegistrationFormPropsDucati } from "./registration-form/props-enrichers-categories/ducatiConfirmRegistration";
import { enrichRegistrationFormPropsMsdType3 } from "./registration-form/props-enrichers-categories/msdType3";
export var allRegistrationFormsPropsEnrichers = {
    // TODO: check every form individually before using it. Do not assume that they work.
    // TODO: check if 0 default is correct
    0: enrichRegistrationFormPropsMsd,
    1: enrichRegistrationFormPropsMsdSpecial,
    2: enrichRegistrationFormPropsMsdSpecial,
    3: enrichRegistrationFormPropsEurogin,
    4: enrichRegistrationFormPropsMsdSpecial,
    5: enrichRegistrationFormPropsMsdId,
    6: enrichRegistrationFormPropsMsdSpecial,
    7: enrichRegistrationFormPropsMsd,
    8: enrichRegistrationFormPropsMsd,
    9: enrichRegistrationFormPropsMsd,
    10: enrichRegistrationFormPropsMsd,
    11: enrichRegistrationFormPropsMsdGlobalHpv,
    12: enrichRegistrationFormPropsMsd,
    13: enrichRegistrationFormPropsMsd,
    14: enrichRegistrationFormPropsMsd,
    15: enrichRegistrationFormPropsMsd,
    16: enrichRegistrationFormPropsMsd,
    17: enrichRegistrationFormPropsMsd,
    18: enrichRegistrationFormPropsMsdApidForumCovid19,
    19: enrichRegistrationFormPropsMsdGlobalCmv,
    20: enrichRegistrationFormPropsOrganonVirtualSass,
    21: enrichRegistrationFormPropsMsdHncrResearchForum,
    22: enrichRegistrationFormPropsGynecCancer,
    23: enrichRegistrationFormPropsMsdType2,
    24: enrichRegistrationFormPropsMsdPedVaccines,
    // TODO: add registration_flow in store, clarifications on registration_mode and episode_categories_id
    1001: enrichRegistrationFormPropsMsdCtio,
    1101: enrichRegistrationFormAnimalHealth,
    2001: enrichRegistrationFormPropsEdizioniDm,
    3023: enrichRegistrationFormPropsMsdType3,
    3026: enrichRegistrationFormPropsMsdType2,
    4001: enrichRegistrationFormPropsDucati,
    4002: enrichRegistrationFormStandardPasswordCompany,
    // confirm registrations
    7001: enrichConfirmRegistrationFormPropsDucati
};
var enrichRegistrationFormProps = function (props) {
    var currentRegistrationForm = useSelector(selectRegistrationFormId);
    var _a = currentRegistrationForm && allRegistrationFormsPropsEnrichers[currentRegistrationForm] !== undefined
        ? allRegistrationFormsPropsEnrichers[currentRegistrationForm]()
        : { inputs: [], onChange: undefined }, inputs = _a.inputs, onChange = _a.onChange;
    return __assign(__assign({}, props), { inputs: inputs, onChange: onChange });
};
export default enrichRegistrationFormProps;
