import { selectUser } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import { ducatiNationalMeetings } from "./ducatiNationalMeetings";
export var useGetDucatiNationalMeetings = function () {
    var _a, _b, _c;
    var identity = useSelector(selectUser).identity;
    var country = identity === null || identity === void 0 ? void 0 : identity.country_id;
    // @ts-ignore
    var isMeetingView = (_a = identity === null || identity === void 0 ? void 0 : identity.subscription) === null || _a === void 0 ? void 0 : _a.is_meeting_view;
    // @ts-ignore
    var meetingListFromSubData = (_c = (_b = identity === null || identity === void 0 ? void 0 : identity.subscription) === null || _b === void 0 ? void 0 : _b.subscription_data) === null || _c === void 0 ? void 0 : _c.national_meetings;
    if (Array.isArray(meetingListFromSubData) && meetingListFromSubData.length) {
        var nationalMeetings = meetingListFromSubData.map(function (nationalMeetingCode) {
            return ducatiNationalMeetings[nationalMeetingCode];
        });
        var nationalMeetingsFiltered = nationalMeetings.filter(function (meeting) { return meeting !== undefined; });
        return nationalMeetingsFiltered;
    }
    else if (isMeetingView) {
        return [
            ducatiNationalMeetings.mx,
            ducatiNationalMeetings.us,
            ducatiNationalMeetings.it,
            ducatiNationalMeetings.nl,
            ducatiNationalMeetings.fr
        ];
    }
    else {
        var availableMeeting = ducatiNationalMeetings[country];
        if (availableMeeting === undefined)
            return [];
        var availableMeetings = [availableMeeting];
        return availableMeetings;
    }
};
