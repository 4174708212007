import { PusherconfigInstance } from "./PusherWrapper.class";
import { PusherMock } from "pusher-js-mock";
export var FORCE_LOGOUT_PUSHER_EVENT = "App\\Events\\ForceLogout";
export var ACCEPT_USER_PUSHER_EVENT = "App\\Events\\AcceptUser";
export var CHANNEL_STATUS_CHANGE_EVENT = "App\\Events\\ChannelStatusChanged";
export var CHANGE_QA_ENABLED_STATUS_PUSHER_EVENT = "App\\Events\\ChangeEpisodeQaaStatus";
var setChannel = function (channel) {
    PusherconfigInstance.setChannel(channel);
};
export var subscribeToPusherChannel = function (_a) {
    var pusherInstance = _a.pusherInstance, _b = _a.config, token = _b.token, appId = _b.appId, userId = _b.userId, endSessionCallback = _a.endSessionCallback, waitingRoomAcceptedCallback = _a.waitingRoomAcceptedCallback, channelStatusChangeCallback = _a.channelStatusChangeCallback, qaStatusChangeCallback = _a.qaStatusChangeCallback;
    if (window === null || window === void 0 ? void 0 : window.Cypress) {
        var pusherInstance_1 = new PusherMock();
        fetch(process.env.PUSHER_BASE_URL + "broadcasting/auth", { method: "POST" });
        var channel = pusherInstance_1.subscribe("presence-".concat(appId, ".none"));
        channel.bind(FORCE_LOGOUT_PUSHER_EVENT, function (_a) {
            var usersToDisconnect = _a.remove;
            if (usersToDisconnect === null || usersToDisconnect === void 0 ? void 0 : usersToDisconnect.includes(userId))
                endSessionCallback();
        });
        channel.bind(ACCEPT_USER_PUSHER_EVENT, function (_a) {
            var acceptedUsers = _a.accept;
            if (acceptedUsers === null || acceptedUsers === void 0 ? void 0 : acceptedUsers.includes(userId))
                waitingRoomAcceptedCallback();
        });
        channel.bind(CHANNEL_STATUS_CHANGE_EVENT, function (actions) {
            channelStatusChangeCallback(actions);
        });
        channel.bind(CHANGE_QA_ENABLED_STATUS_PUSHER_EVENT, function (status) {
            qaStatusChangeCallback(status);
        });
        if (window.Cypress) {
            window.Cypress.pusher = pusherInstance_1;
        }
        channel.unsubscribe = function () { };
        return channel;
    }
    else {
        try {
            if (pusherInstance) {
                var channel = pusherInstance.subscribe("presence-".concat(appId, ".none"));
                setChannel(channel);
                channel.bind(FORCE_LOGOUT_PUSHER_EVENT, function (_a) {
                    var usersToDisconnect = _a.remove;
                    if (usersToDisconnect === null || usersToDisconnect === void 0 ? void 0 : usersToDisconnect.includes(userId))
                        endSessionCallback();
                });
                channel.bind(ACCEPT_USER_PUSHER_EVENT, function (_a) {
                    var acceptedUsers = _a.accept;
                    if (acceptedUsers === null || acceptedUsers === void 0 ? void 0 : acceptedUsers.includes(userId))
                        waitingRoomAcceptedCallback();
                });
                channel.bind(CHANNEL_STATUS_CHANGE_EVENT, function (actions) {
                    channelStatusChangeCallback(actions);
                });
                channel.bind(CHANGE_QA_ENABLED_STATUS_PUSHER_EVENT, function (status) {
                    qaStatusChangeCallback(status);
                });
                return channel;
            }
            else {
                return null;
            }
        }
        catch (_c) {
            console.warn("Error in pusher connection / main");
            return null;
        }
    }
};
